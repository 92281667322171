// TYPES
const SET_PROGRESS_ALERT = 'progressAlert/SET_PROGRESS_ALERT';
const REMOVE_PROGRESS_ALERT = 'progressAlert/REMOVE_PROGRESS_ALERT';

// INITIAL STATE

const initialState = {
  type: '',
  icon: '',
  title: '',
  text: '',
};

// REDUCER

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PROGRESS_ALERT:
      return { ...payload };
    case REMOVE_PROGRESS_ALERT:
      return null;
    default:
      return state;
  }
}

// ACTIONS

export const setProgressAlert = (type, title, text) => (dispatch) => {
  dispatch({
    type: SET_PROGRESS_ALERT,
    payload: { type, title, text },
  });
};

export const removeProgressAlert = () => (dispatch) => {
  dispatch({ type: REMOVE_PROGRESS_ALERT });
};
