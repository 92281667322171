import React, { useRef, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const CustomAreaChart = ({
  data,
  width,
  height = 300,
  xDataKey = 'name',
  yDataKey = 'value',
  strokeColor = '#1B2559',
  xTickColor = '#A3AED0',
  yTickColor = '#666',
  xFontSize = 17,
  areaColor = '#B0BBD5',
  CustomDot = () => {},
  CustomToolTip = () => {},
  toolTipEndCharacter = '',
  xCustomTickFormatter = (value) => value,
  yCustomTickFormatter = (value) => value,
}) => {
  const areaRef = useRef(null);
  const [point, setPoint] = useState(null);

  const onChartMouseMove = (chart) => {
    if (chart.isTooltipActive) {
      const currentPoint = areaRef.current.props.points[chart.activeTooltipIndex];

      if (currentPoint !== point) {
        setPoint(currentPoint);
      }
    }
  };

  const onChartMouseLeave = () => {
    setPoint(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <ResponsiveContainer height={height} width={width}>
        <AreaChart
          data={data}
          margin={{
            top: 25,
            right: 15,
            left: 0,
            bottom: 5,
          }}
          onMouseLeave={onChartMouseLeave}
          onMouseMove={onChartMouseMove}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={xDataKey}
            tick={{
              fill: xTickColor,
              fontSize: xFontSize,
              fontFamily: 'Inter, sans-serif',
            }}
            tickFormatter={xCustomTickFormatter}
            tickLine={false}
          />
          <YAxis
            axisLine={false}
            domain={['auto', 'auto']}
            tick={{
              fill: yTickColor,
              fontSize: 12,
              fontFamily: 'Inter,  sans-serif',
            }}
            tickCount={4}
            tickFormatter={yCustomTickFormatter}
            tickLine={false}
          />
          <Area
            activeDot={<CustomDot />}
            dataKey={yDataKey}
            fill={areaColor}
            ref={areaRef}
            stroke={strokeColor}
            strokeWidth={4}
            type="monotone"
          />
          <Tooltip content={() => null} cursor={false} />
        </AreaChart>
      </ResponsiveContainer>
      <CustomToolTip
        height={height}
        point={point}
        toolTipEndCharacter={toolTipEndCharacter}
        xDataKey={xDataKey}
        yDataKey={yDataKey}
      />
    </div>
  );
};

export default CustomAreaChart;
