import React from 'react';
import { useFetch } from '../../../hooks';
import VideoDetailsTable from '../../components/videoDetailsTable/VideoDetailsTable';
import styles from './MyVideos.module.scss';

const REQUEST_CACHE_TTL_IN_SECONDS = 30;

const MyVideos = () => {
  const { data } = useFetch(
    `${process.env.REACT_APP_CREATOR_URL}/myvideos?column=liveDateTime&direction=desc`,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  return (
    <div className={styles.myVideosContainer}>
      <div className={styles.aboveSection}>
        <span>
          <h1>Video details</h1>
        </span>
      </div>
      <VideoDetailsTable data={data} />
    </div>
  );
};

export default MyVideos;
