import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import Helpers from '../../../utils/Helpers';
import CustomProgressBar from '../../charts/ProgressBar/ProgressBar';
import WidgetTitle from '../widgetTitle/WidgetTitle';
import styles from './UserTypeWidget.module.scss';

const UserTypeWidget = ({ data, Chart = () => {}, loading = false }) => {
  const freeUsersPercentage = data
    ? Helpers.calculatePercentage(data.freeUsers, data.totalUsers)
    : 0;

  const vipUsersPercentage = data ? Helpers.calculatePercentage(data.vipUsers, data.totalUsers) : 0;

  return (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <WidgetTitle description="The type of users watching your videos" title="User Type" />
          <CustomProgressBar color="#1B2559" label="VIP" percentage={vipUsersPercentage} />
          <CustomProgressBar color="#A3AED0" label="Free" percentage={freeUsersPercentage} />
          <div className={styles.chartWrapper}>
            <Chart
              colors={['#1B2559', '#A3AED0']}
              data={[{ value: vipUsersPercentage }, { value: freeUsersPercentage }]}
              height={137}
              outerRadius={65}
              width={137}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UserTypeWidget;
