import React from 'react';

export const CustomLabel = ({ viewBox, max, fill }) => {
  const { x, y, width, height } = viewBox.viewBox;
  const centerX = x + width;
  const centerY = y + height;

  return (
    <g>
      <rect fill="white" height={20} width={50} x={centerX - x} y={centerY - 10} />
      <text
        dy={3}
        fill={fill || '#074DFF'}
        fontFamily="Inter, sans-serif"
        fontSize={12}
        textAnchor="middle"
        x={centerX}
        y={centerY}
      >
        ${max}
      </text>
    </g>
  );
};
