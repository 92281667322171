import React, { useState } from 'react';
import {
  EarningsTabs,
  IAudioRoomsEarnings,
  IEarningsMilestones,
  IInfluencerEarningsConfiguration,
  IPromoCodeEarnings,
  IRaffleDrawEarnings,
  IShopSalesEarnings,
  IVideoEarnings,
  IVideoRoomsEarnings,
  IVuzPayEarnings,
} from '../../../common/types';
import { useFetch } from '../../../hooks';
import { applyQueryParameters } from '../../../utils';
import PeriodSelectUtils, { IDatePeriod } from '../../components/periodSelect/PeriodSelectUtils';
import VideoStatusTag from '../../components/videoStatusTag/VideoStatusTag';
import Helpers from '../../utils/Helpers';

const configurationUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/configuration`;
const videoEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/videos`;
const milestonesEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/milestones`;
const audioRoomsEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/audio-rooms`;
const videoRoomsEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/video-rooms`;
const shopSalesEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/shop-sales`;
const raffleDrawsEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/raffle-draws`;
const promoCodesEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/promo-codes`;
const vuzPayEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/vuz-pay`;

const transformPromoCodesEarningsTableData = (data?: IPromoCodeEarnings['earnings'] | null) => {
  if (!data) {
    return [];
  }

  return data.map((promoCode) => ({
    date: { field: 'Date', value: Helpers.formatDate(promoCode.date) },
    subscribers: { field: 'Subscribers', value: Helpers.formatNumber(promoCode.subscribers) },
    earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(promoCode.earnings)}` },
  }));
};

const transformRaffleDrawEarningsTableData = (data?: IRaffleDrawEarnings['earnings'] | null) => {
  if (!data) {
    return [];
  }

  return data.map((raffleDraw) => ({
    raffleDrawName: { field: 'Raffle Draw Name', value: raffleDraw.name },
    startDate: { field: 'Start date', value: Helpers.formatDate(raffleDraw.startDate) },
    endDate: { field: 'End date', value: Helpers.formatDate(raffleDraw.endDate) },
    entries: { field: 'Entries', value: Helpers.formatNumber(raffleDraw.entries) },
    earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(raffleDraw.earnings)}` },
  }));
};

const transformShopSalesEarningsTableData = (data?: IShopSalesEarnings['earnings'] | null) => {
  if (!data) {
    return [];
  }

  return data.map((shopSale) => ({
    productName: { field: 'Product Name', value: shopSale.name },
    date: { field: 'Date', value: Helpers.formatDate(shopSale.date) },
    unitsSold: { field: 'Units sold', value: Helpers.formatNumber(shopSale.unitsSold) },
    earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(shopSale.earnings)}` },
  }));
};

const transformAudioRoomsEarningTableData = (data?: IAudioRoomsEarnings['earnings'] | null) => {
  if (!data) {
    return [];
  }

  return data.map((audioRoom) => ({
    roomName: { field: 'Room name', value: audioRoom.roomName },
    date: { field: 'Date', value: Helpers.formatDate(audioRoom.date) },
    gifts: { field: 'Gifts', value: Helpers.formatNumber(audioRoom.gifts) },
    points: { field: 'Points', value: Helpers.formatNumber(audioRoom.points) },
    users: { field: 'Users', value: Helpers.formatNumber(audioRoom.users) },
    joinsEarnings: {
      field: 'Joins Earnings',
      value: `$${Helpers.formatNumber(audioRoom.joinsEarnings)}`,
    },
    giftsEarnings: {
      field: 'Gifts earnings',
      value: `$${Helpers.formatNumber(audioRoom.giftsEarnings)}`,
    },
  }));
};

const transformVideoRoomsEarningTableData = (data?: IVideoRoomsEarnings['earnings'] | null) => {
  if (!data) {
    return [];
  }

  return (
    data?.map((videoRoom) => ({
      roomName: { field: 'Room name', value: videoRoom.roomName },
      date: { field: 'Date', value: Helpers.formatDate(videoRoom.date) },
      gifts: { field: 'Gifts', value: Helpers.formatNumber(videoRoom.gifts) },
      points: { field: 'Points', value: Helpers.formatNumber(videoRoom.points) },
      users: { field: 'Users', value: Helpers.formatNumber(videoRoom.users) },
      joinsEarnings: {
        field: 'Joins Earnings',
        value: `$${Helpers.formatNumber(videoRoom.joinsEarnings)}`,
      },
      giftsEarnings: {
        field: 'Gifts earnings',
        value: `$${Helpers.formatNumber(videoRoom.giftsEarnings)}`,
      },
    })) || []
  );
};

const transformVideosEarningsTableData = (data?: IVideoEarnings['earnings'] | null) =>
  data?.map((video) => ({
    img: {
      field: 'Thumbnail',
      value: <img alt={video.title} src={video.thumbnail || ''} />,
    },
    title: { field: 'Video Title', value: video.title },
    views: { field: 'Views', value: Helpers.formatNumber(video.totalViews) },
    likes: { field: 'Likes', value: Helpers.formatNumber(video.likesCount) },
    shares: { field: 'Shares', value: Helpers.formatNumber(video.sharesCount) },
    comments: { field: 'Comments', value: Helpers.formatNumber(video.commentsCount) },
    type: {
      field: 'Type',
      value: (
        <VideoStatusTag isPremium={Boolean(video.isPremium)} previewTime={video.previewTime} />
      ),
    },
    earning: { field: 'Video Earnings', value: `$${Helpers.formatNumber(video.earning)}` },
  })) || [];

const transformVuzPayEarningsTableData = (data?: IVuzPayEarnings['earnings'] | null) => {
  if (!data) {
    return [];
  }

  return data.map((purchase) => ({
    date: { field: 'Date', value: Helpers.formatDate(purchase.date) },
    points: { field: 'Amount Purchase', value: `$${purchase.price}` },
    earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(purchase.earnings)}` },
  }));
};

export const useEarningsData = () => {
  const [datePeriod, setDatePeriod] = useState<IDatePeriod>(PeriodSelectUtils.thisYear());

  const fromDate = datePeriod.fromDate?.toISOString().split('T')[0];
  const toDate = datePeriod.toDate?.toISOString().split('T')[0];

  const isDatePeriodSelected = fromDate && toDate;

  const { data: configuration } = useFetch<IInfluencerEarningsConfiguration>(configurationUrl);

  const { data: milestonesEarnings } = useFetch<IEarningsMilestones>(
    configuration ? milestonesEarningsUrl : null
  );

  const videosEarnings = useFetch<IVideoEarnings>(
    configuration?.videoViewsEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(videoEarningsUrl, { from: fromDate, to: toDate })
        : videoEarningsUrl
      : null
  );

  const audioRoomsEarnings = useFetch<IAudioRoomsEarnings>(
    configuration?.audioRoomsEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(audioRoomsEarningsUrl, { from: fromDate, to: toDate })
        : audioRoomsEarningsUrl
      : null
  );

  const videoRoomsEarnings = useFetch<IVideoRoomsEarnings>(
    configuration?.videoRoomsEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(videoRoomsEarningsUrl, { from: fromDate, to: toDate })
        : videoRoomsEarningsUrl
      : null
  );

  const shopSalesEarnings = useFetch<IShopSalesEarnings>(
    configuration?.shopRevenueEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(shopSalesEarningsUrl, { from: fromDate, to: toDate })
        : shopSalesEarningsUrl
      : null
  );

  const raffleDrawsEarnings = useFetch<IRaffleDrawEarnings>(
    configuration?.raffleDrawEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(raffleDrawsEarningsUrl, { from: fromDate, to: toDate })
        : raffleDrawsEarningsUrl
      : null
  );

  const promoCodesEarnings = useFetch<IPromoCodeEarnings>(
    configuration?.promoCodeEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(promoCodesEarningsUrl, { from: fromDate, to: toDate })
        : promoCodesEarningsUrl
      : null
  );

  const vuzPayEarnings = useFetch<IVuzPayEarnings>(
    configuration?.vuzPayEarningsEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(vuzPayEarningsUrl, { from: fromDate, to: toDate })
        : vuzPayEarningsUrl
      : null
  );

  const isTotalDataLoading =
    videosEarnings.isLoading ||
    audioRoomsEarnings.isLoading ||
    videoRoomsEarnings.isLoading ||
    shopSalesEarnings.isLoading ||
    raffleDrawsEarnings.isLoading ||
    promoCodesEarnings.isLoading ||
    vuzPayEarnings.isLoading;

  return {
    setDatePeriod,
    isTotalDataLoading,
    milestonesEarnings,
    configuration,
    isVideoEarningsLoading: videosEarnings.isLoading,
    videosEarnings,
    isAudioRoomsEarningsLoading: audioRoomsEarnings.isLoading,
    audioRoomsEarnings,
    isVideoRoomsEarningsLoading: videoRoomsEarnings.isLoading,
    videoRoomsEarnings,
    isShopSalesEarningsLoading: shopSalesEarnings.isLoading,
    shopSalesEarnings,
    isRaffleDrawsEarningsLoading: raffleDrawsEarnings.isLoading,
    raffleDrawsEarnings,
    isPromoCodesEarningsLoading: promoCodesEarnings.isLoading,
    promoCodesEarnings,
    isVuzPayEarningsLoading: vuzPayEarnings.isLoading,
    vuzPayEarnings,
  };
};

export const getEarningsDataMap = (
  configuration: IInfluencerEarningsConfiguration,
  {
    videosEarnings: { data: videoEarnings },
    audioRoomsEarnings: { data: audioRoomsEarnings },
    videoRoomsEarnings: { data: videoRoomsEarnings },
    shopSalesEarnings: { data: shopSalesEarnings },
    raffleDrawsEarnings: { data: raffleDrawsEarnings },
    promoCodesEarnings: { data: promoCodesEarnings },
    vuzPayEarnings: { data: vuzPayEarnings },
  }: {
    videosEarnings: { data: IVideoEarnings | null };
    audioRoomsEarnings: { data: IAudioRoomsEarnings | null };
    videoRoomsEarnings: { data: IVideoRoomsEarnings | null };
    shopSalesEarnings: { data: IShopSalesEarnings | null };
    raffleDrawsEarnings: { data: IRaffleDrawEarnings | null };
    promoCodesEarnings: { data: IPromoCodeEarnings | null };
    vuzPayEarnings: { data: IVuzPayEarnings | null };
  }
) => {
  const tabs: {
    key: EarningsTabs;
    label: string;
    data?: {
      date: string | Date;
      earning: number;
    }[];
    tableData:
      | ReturnType<typeof transformVideosEarningsTableData>
      | ReturnType<typeof transformAudioRoomsEarningTableData>
      | ReturnType<typeof transformVideoRoomsEarningTableData>
      | ReturnType<typeof transformShopSalesEarningsTableData>
      | ReturnType<typeof transformRaffleDrawEarningsTableData>
      | ReturnType<typeof transformPromoCodesEarningsTableData>
      | ReturnType<typeof transformVuzPayEarningsTableData>;
    tableTitle: string;
  }[] = [];

  if (!configuration) {
    return;
  }

  if (configuration.videoViewsEarningEnabled) {
    tabs.push({
      key: EarningsTabs.VideoEarnings,
      label: 'Video earnings',
      data: videoEarnings?.chart,
      tableData: transformVideosEarningsTableData(videoEarnings?.earnings),
      tableTitle: 'Video Earnings',
    });
  }

  if (configuration.audioRoomsEarningEnabled) {
    tabs.push({
      key: EarningsTabs.AudioRoomsEarnings,
      label: 'Audio rooms',
      data: audioRoomsEarnings?.chart,
      tableData: transformAudioRoomsEarningTableData(audioRoomsEarnings?.earnings),
      tableTitle: 'Audio rooms Earnings',
    });
  }

  if (configuration.videoRoomsEarningEnabled) {
    tabs.push({
      key: EarningsTabs.VideoRoomsEarnings,
      label: 'Video rooms',
      data: videoRoomsEarnings?.chart,
      tableData: transformVideoRoomsEarningTableData(videoRoomsEarnings?.earnings),
      tableTitle: 'Video rooms Earnings',
    });
  }

  if (configuration.shopRevenueEnabled) {
    tabs.push({
      key: EarningsTabs.ShopSalesEarnings,
      label: 'Shop sales',
      data: shopSalesEarnings?.chart,
      tableData: transformShopSalesEarningsTableData(shopSalesEarnings?.earnings),
      tableTitle: 'Shop sales Earnings',
    });
  }

  if (configuration.raffleDrawEarningEnabled) {
    tabs.push({
      key: EarningsTabs.RaffleDrawsEarnings,
      label: 'Raffle draw',
      data: raffleDrawsEarnings?.chart,
      tableData: transformRaffleDrawEarningsTableData(raffleDrawsEarnings?.earnings),
      tableTitle: 'Raffle draw Earnings',
    });
  }

  if (configuration.promoCodeEarningEnabled) {
    tabs.push({
      key: EarningsTabs.PromoCodesEarnings,
      label: 'Promo codes',
      data: promoCodesEarnings?.chart,
      tableData: transformPromoCodesEarningsTableData(promoCodesEarnings?.earnings),
      tableTitle: 'Promo codes Earnings',
    });
  }

  if (configuration.vuzPayEarningsEnabled) {
    tabs.push({
      key: EarningsTabs.VuzPayEarnings,
      label: 'VUZ Pay',
      data: vuzPayEarnings?.chart,
      tableData: transformVuzPayEarningsTableData(vuzPayEarnings?.earnings),
      tableTitle: 'VUZ Pay Earnings',
    });
  }

  return tabs;
};
