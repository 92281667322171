import React from 'react';
import { ReactComponent as FreeBlueTagIcon } from '../../assets/icons/FreeBlueTagIcon.svg';
import { ReactComponent as FreeGreenTagIcon } from '../../assets/icons/FreeGreenTagIcon.svg';
import { ReactComponent as PreviewTagIcon } from '../../assets/icons/PreviewTagIcon.svg';
import { ReactComponent as VipTagIcon } from '../../assets/icons/VipTagIcon.svg';
import styles from './VideoStatusTag.module.scss';

const VideoStatusTag = ({ isPremium = false, previewTime = 0 }) => (
    <div className={styles.container}>
      {!isPremium ? <FreeGreenTagIcon /> : null}
      {isPremium && previewTime ? (
        <>
          <FreeBlueTagIcon />
          <PreviewTagIcon />
        </>
      ) : null}
      {isPremium && !previewTime ? <VipTagIcon /> : null}
    </div>
  );

export default VideoStatusTag;
