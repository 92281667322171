import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './TableWidget.module.scss';

const TableWidget = ({ className, data, title, loading = false }) => {
  const tableWidgetClassName = classNames(className, styles.tableWidgetContainer, {
    [commonStyles.loading]: loading,
  });

  return (
    <div className={tableWidgetClassName}>
      {!loading ? (
        <>
          <div className={styles.header}>
            <h1>{title}</h1>
          </div>
          <table>
            <thead>
              <tr>
                <th />
                {data.length > 0 &&
                  Object.keys(data[0]).map((key) => <th key={key}>{data[0][key].field}</th>)}
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {Object.keys(row).map((key) => (
                    <td key={key}>{row[key].value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );
};

export default TableWidget;
