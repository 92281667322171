const Helpers = {
  isAuthenticated: () => Boolean(localStorage.getItem('isAuthenticated')),
  applyQueryParameters: (query: unknown, params: Record<string, any>): string | null => {
    if (!query || typeof query !== 'string') {
      return null;
    }

    let composedQuery = query;
    
    if (!composedQuery.includes('?')) {
      composedQuery += '?';
    }

    Object.keys(params).forEach((paramKey) => {
      composedQuery = `${composedQuery}${paramKey}=${params[paramKey].toString()}&`;
    });

    return composedQuery;
  },
};

export default Helpers;
