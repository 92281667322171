import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Helpers from '../utils/Helpers';
import PrivateLayout from './PrivateLayout/PrivateLayout';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = Helpers.isAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <PrivateLayout>
            <Component {...props} />
          </PrivateLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
