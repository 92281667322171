import React from 'react';
import { ReactComponent as CommentsIcon } from '../../assets/icons/CommentsIcon.svg';
import { ReactComponent as FollowersIcon } from '../../assets/icons/FollowersIcon.svg';
import { ReactComponent as GiftIcon } from '../../assets/icons/GiftIcon.svg';
import { ReactComponent as ViewsIcon } from '../../assets/icons/ViewsIcon.svg';
import CustomBarChart from '../../components/charts/BarChart/BarChart';
import ChartWidget from '../../components/widgets/chartWidget/ChartWidget';
import InfluencerStatsWidget from '../../components/widgets/influencerStatsWidget/InfluencerStatsWidget';
import SingleStatsWidget from '../../components/widgets/singleStatsWidget/SingleStatsWidget';
import TableWidget from '../../components/widgets/tableWidget/TableWidget';
import VideoStatsWidget from '../../components/widgets/videoStatsWidget/VideoStatsWidget';
import { VuzPayWidget } from '../../components/widgets/VuzPayWidget';
import Helpers from '../../utils/Helpers';
import styles from './Dashboard.module.scss';
import { useDashboardData } from './use-dashboard-data';

const Dashboard: React.FC = () => {
  const {
    isTotalFollowersLoading,
    isCommentsLoading,
    isReceivedGiftsLoading,
    isUniqueViewsLoading,
    isTotalEarningsLoading,
    isInfluencerInfoLoading,
    isTopWatchedVideosLoading,
    isLatestVideosLoading,
    isVuzPayAnalyticsLoading,
    totalFollowers,
    comments,
    receivedGifts,
    uniqueViews,
    influencerInfo,
    totalEarnings,
    topWatchedVideos,
    latestVideos,
    vuzPayConversion,
  } = useDashboardData();

  return (
    <div className={styles.dashboardWrapper}>
      <div className={styles.dashboardSearch}>
        <div className={styles.dashboardGreeting}>
          <p>Hello {influencerInfo?.name},</p>
          <h1>Welcome to VUZ!</h1>
        </div>
      </div>
      <div className={styles.widgetsContainer}>
        <SingleStatsWidget
          icon={<FollowersIcon />}
          loading={isTotalFollowersLoading}
          name="Total Followers"
          value={Helpers.formatNumber(totalFollowers)}
        />
        <SingleStatsWidget
          icon={<CommentsIcon />}
          loading={isCommentsLoading}
          name="Total Comments"
          value={Helpers.formatNumber(comments)}
        />
        <SingleStatsWidget
          icon={<GiftIcon />}
          loading={isReceivedGiftsLoading}
          name="Received Gifts"
          value={Helpers.formatNumber(receivedGifts)}
        />
        <SingleStatsWidget
          icon={<ViewsIcon />}
          loading={isUniqueViewsLoading}
          name="Unique Views"
          value={Helpers.formatNumber(uniqueViews)}
        />
        <VuzPayWidget data={vuzPayConversion} isLoading={isVuzPayAnalyticsLoading} />
        <InfluencerStatsWidget data={influencerInfo} loading={isInfluencerInfoLoading} />
        <ChartWidget Chart={CustomBarChart} data={totalEarnings} loading={isTotalEarningsLoading} />
        <TableWidget
          className={styles.tableVideoTitle}
          data={topWatchedVideos}
          loading={isTopWatchedVideosLoading}
          title="Top watched videos"
        />
        <VideoStatsWidget data={latestVideos} loading={isLatestVideosLoading} />
      </div>
    </div>
  );
};

export default Dashboard;
