import React from 'react';
import { ReactComponent as ChainIcon } from '../../../assets/icons/ChainIcon.svg';
import Helpers from '../../../utils/Helpers';
import SingleStatsWidget from '../singleStatsWidget/SingleStatsWidget';
import styles from './VuzPayWidget.module.scss';

interface IVuzPayWidgetProps {
  isLoading: boolean;
  data?: {
    clicks: number;
    purchases: number;
  } | null;
}

export const VuzPayWidget: React.FC<IVuzPayWidgetProps> = ({ isLoading, data }) => {
  const clicks = data ? Helpers.formatNumber(data.clicks) : 0;
  
  const conversionPercentage =
    data?.purchases && data?.clicks ? ((data.purchases / data.clicks) * 100).toFixed(2) : 0;

  return (
    <SingleStatsWidget icon={<ChainIcon />} loading={isLoading} name="VUZ Pay">
      <div className={styles['vuz-pay-analytics']}>
        <div>
          <h1>{clicks}</h1>
          <p>Clicks</p>
        </div>
        <div className={styles.divider} />
        <div>
          <h1>{conversionPercentage}%</h1>
          <p>Converted</p>
        </div>
      </div>
    </SingleStatsWidget>
  );
};
