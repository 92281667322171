import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ReactComponent as StatisticIcon } from '../../../assets/icons/StatisticsIcon.svg';
import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './SingleStatsWidget.module.scss';

interface ISingleStatsWidgetProps {
  icon?: React.ReactNode;
  name: string;
  value?: string | number;
  statistic?: string;
  iconBackground?: string;
  loading?: boolean;
}

const SingleStatsWidget: React.FC<PropsWithChildren<ISingleStatsWidgetProps>> = ({
  children,
  icon,
  name,
  value = 0,
  statistic,
  iconBackground = '#074DFF',
  loading = false,
}) => {
  const widgetContainerClassName = classNames(styles.singleStatsWidgetContainer, {
    [commonStyles.loading]: loading,
  });

  const statisticsClassName = classNames(styles.statsContainer, {
    [styles.negativeStatistic]: !!statistic && parseFloat(statistic) < 0,
  });

  return (
    <div className={widgetContainerClassName}>
      {!loading ? (
        <>
          <div className={styles.iconContainer} style={{ background: iconBackground }}>
            {icon}
          </div>
          <div className={styles.statsDescription}>
            <p>{name}</p>
            {value ? <h1>{value}</h1> : children}
          </div>
          {statistic && (
            <div className={statisticsClassName}>
              <p>{statistic}</p>
              <StatisticIcon />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SingleStatsWidget;
