import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ViewAllArrow } from '../../../assets/icons/ViewAllArrow.svg';
import commonStyles from '../../../assets/styles/Common.module.scss';
import { CustomLabel } from '../../charts/BarChart/components/CustomLabel';
import { CustomTooltip } from '../../charts/BarChart/components/CustomTooltip';
import styles from './ChartWidget.module.scss';

const ChartWidget = ({ data, Chart = () => {}, loading = false }) => (
    <div className={classNames(styles.chartContainer, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <div className={styles.chartInfo}>
            <div className={styles.description}>
              <div className={styles.totalEarnings}>
                <p>Total Earnings for this year</p>
                <div className={styles.viewAll}>
                  <Link to="/dashboard/earnings">
                    <span>View all</span>
                    <ViewAllArrow />
                  </Link>
                </div>
              </div>
              <span>
                <h1>${data?.totalEarnings}</h1>
              </span>
            </div>
          </div>
          <Chart
            CustomLabel={CustomLabel}
            CustomTooltip={CustomTooltip}
            data={data.earnings}
            height={250}
          />
        </>
      ) : null}
    </div>
  );

export default ChartWidget;
