import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { resetForm, sendResetLink } from '../../../../store/auth';
import Helpers from '../../../utils/Helpers';
import BaseAuth from '../../auth/BaseAuth/BaseAuth';
import DynamicScrollToTop from '../../layout/DynamicScrollToTop';
import authStyles from './Auth.module.scss';

const ForgotPassword = ({ history }) => {
  const dispatch = useDispatch();
  const { btnLoading, formResultRetrieved, isAuthenticated } = useSelector((state) => state.auth);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [errors, setErrors] = useState({
    email: null,
  });
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (name === 'email') {
      if (!value) {
        setErrors({ ...errors, email: 'Email is required' });
        setIsFormValid(false);
        return;
      }

      if (!Helpers.isEmail(value)) {
        setErrors({ ...errors, email: 'Invalid email' });
        setIsFormValid(false);
        return;
      }
    }

    setErrors({ ...errors, email: null });
    setIsFormValid(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setErrors((err) => ({ ...err, email: 'Email is required' }));
    }

    if (!isFormValid) {return;}

    dispatch(sendResetLink(email, history));
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (formResultRetrieved) {
      setIsModalVisible(true);
      setFormData({ email: '' });
      dispatch(resetForm());
    }
  }, [formResultRetrieved, dispatch]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <BaseAuth>
      <DynamicScrollToTop />

      <form className={authStyles.authForm} onSubmit={(e) => onSubmit(e)}>
        <div className={classNames(authStyles.authCard, 'mt-3')}>
          <h2>Forgot Password</h2>
          <br />

          <div className="form-group">
            <label className={authStyles.authLabel}>Email Address</label>
            <input
              className={classNames(authStyles.authInput, errors.email && authStyles.inputError)}
              name="email"
              onChange={(e) => onChange(e)}
              placeholder="Type your email here"
              value={email}
            />
            {errors.email && <label className={authStyles.labelError}>{errors.email}</label>}
          </div>

          <button
            className={classNames(authStyles.authPrimaryBtn, 'btn-block', 'mt-3')}
            disabled={btnLoading}
          >
            {btnLoading && <i className="fas fa-circle-notch fa-spin" />}
            Submit
          </button>
        </div>

        <Modal
          centered
          className={authStyles.registerSuccessModal}
          onHide={handleCloseModal}
          show={isModalVisible}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title />
          </Modal.Header>
          <Modal.Body>
            <p className={authStyles.modalTitle}>Password Reset Sent!</p>
            <p className={classNames(authStyles.modalText, 'mt-4')}>
              Please check your email for a reset link
            </p>
          </Modal.Body>
        </Modal>
      </form>
    </BaseAuth>
  );
};

export default withRouter(ForgotPassword);
