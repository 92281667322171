import { IDashboardInitialEarnings, IInfluencerEarningsConfiguration } from '../../../common/types';
import Helpers from '../../utils/Helpers';

const monthsNames = Helpers.getShortedMonthNames();

export const getDashboardInitialEarningsData = (
  configuration?: IInfluencerEarningsConfiguration | null,
) => monthsNames.map((monthName) => {
    const monthData: IDashboardInitialEarnings = {
      name: monthName,
      value: 0,
      extended: {},
    };

    if (configuration?.videoViewsEarningEnabled) {
      monthData.extended.videoEarnings = {
        title: 'Video Earnings',
        count: 0,
        earning: 0,
      };
    }

    if (configuration?.audioRoomsEarningEnabled) {
      monthData.extended.voiceRoomsEarnings = {
        title: 'Audio Rooms',
        count: 0,
        earning: 0,
      };
    }

    if (configuration?.videoRoomsEarningEnabled) {
      monthData.extended.videoRoomsEarnings = {
        title: 'Video Rooms',
        count: 0,
        earning: 0,
      };
    }

    if (configuration?.shopRevenueEnabled) {
      monthData.extended.shopSalesEarnings = {
        title: 'Shop',
        count: 0,
        earning: 0,
      };
    }

    if (configuration?.raffleDrawEarningEnabled) {
      monthData.extended.raffleDrawEarnings = {
        title: 'Raffle draw',
        count: 0,
        earning: 0,
      };
    }

    if (configuration?.promoCodeEarningEnabled) {
      monthData.extended.promoCodesEarnings = {
        title: 'Promo codes',
        count: 0,
        earning: 0,
      };
    }

    return monthData;
  });
