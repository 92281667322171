import axios from 'axios';
import Helpers from '../upload/utils/Helpers';
import setAuthToken from '../upload/utils/setAuthToken';
import { setAlert } from './alert';
import { removeProgressAlert } from './progressAlert';

// TYPES
const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'auth/REGISTER_FAIL';
const USER_LOADED = 'auth/USER_LOADED';
const AUTH_ERROR = 'auth/AUTH_ERROR';
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'auth/LOGIN_FAIL';
const LOGOUT = 'auth/LOGOUT';
// const GET_PROFILE = 'auth/GET_PROFILE';
// const UPDATE_PROFILE = 'auth/UPDATE_PROFILE';
// const CLEAR_PROFILE = 'auth/CLEAR_PROFILE';
// const PROFILE_ERROR = 'auth/PROFILE_ERROR';
const ACCOUNT_DELETED = 'auth/ACCOUNT_DELETED';
const RESET_FORM = 'auth/RESET_FORM';
const FORM_SUBMITTED = 'auth/FORM_SUBMITTED';
const FORM_FINISHED = 'auth/FORM_FINISHED';
const PIN_SUCCESS = 'auth/PIN_SUCCESS';

// INITIAL STATE
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  btnLoading: false,
  formResultRetrieved: false,
  user: null,
  passwordResetToken: null,
};

// REDUCER

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      localStorage.setItem('isAuthenticated', true);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        formResultRetrieved: true,
        btnLoading: false,
        loading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        formResultRetrieved: false,
        btnLoading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.access_token);
      localStorage.setItem('isAuthenticated', true);
      return {
        ...state,
        token: payload.access_token,
        isAuthenticated: true,
        loading: false,
        btnLoading: false,
        user: {
          id: payload.id,
          name: payload.name,
          email: payload.email,
          image: payload.image,
          phone: payload.phone,
          channel_id: payload.channel_id,
        },
      };
    case LOGIN_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
        btnLoading: false,
      };
    case ACCOUNT_DELETED:
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      return { ...initialState };
    case RESET_FORM:
      return {
        ...state,
        formResultRetrieved: false,
      };
    case FORM_SUBMITTED:
      return {
        ...state,
        btnLoading: true,
      };
    case FORM_FINISHED:
      return {
        ...state,
        btnLoading: false,
      };
    case PIN_SUCCESS:
      return {
        ...state,
        btnLoading: false,
        passwordResetToken: payload.verification_token,
      };

    default:
      return state;
  }
}

// ACTIONS

// Register User
export const register = (formData) => async (dispatch) => {
  // const config = Helpers.getAxiosHeadersConfig();
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
    },
  };
  // const body = JSON.stringify({ name, email });
  // console.log('body');
  // console.log(body);

  dispatch({
    type: FORM_SUBMITTED,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/jwt/register`,
      formData,
      config,
    );

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err.response);
    const {errors} = err.response.data;
    const firstError = errors[0];
    console.log(firstError);

    if (firstError.email) {
      dispatch(setAlert(firstError.email[0], 'danger'));
      // errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login =
  ({ email, password }, history) =>
  async (dispatch) => {
    const config = Helpers.getAxiosHeadersConfig();
    const body = JSON.stringify({ email, password });

    dispatch({
      type: FORM_SUBMITTED,
    });

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/login`, body, config);
      // console.log(res.data);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });

      dispatch(removeProgressAlert());

      if (res.data.data.access_token) {
        setAuthToken(res.data.data.access_token);
      }

      history.push('/upload');
    } catch (err) {
      console.log('err');

      if (!err.response) {
        dispatch(setAlert('Server Error', 'danger'));
      } else {
        console.log(err.response.data);
        const {errors} = err.response.data;
        const {message} = err.response.data;

        if (errors) {
          // errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
        }

        if (message) {
          dispatch(setAlert('Invalid credentials', 'danger'));
          // dispatch(setAlert(message, 'danger'));
        }
      }

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Load User
export const loadUser = () => async (dispatch) => {
  const token = localStorage.getItem('token');

  if (token) {
    setAuthToken(token);
  }

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/me`).then((res) => res.data);

    if (!res.data) {
      dispatch({ type: LOGOUT });

      if (window) {
        window.history.go('/login');
      }

      return;
    }

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Logout / Clear Profile
export const logout =
  (history, withRedirect = true) =>
  (dispatch) => {
    dispatch({ type: LOGOUT });

    if (withRedirect) {
      history.push('/login');
    }
  };

// Reset Form
export const resetForm = () => (dispatch) => {
  dispatch({ type: RESET_FORM });
};

// Send Reset Link
export const sendResetLink = (email, history) => async (dispatch) => {
  const config = Helpers.getAxiosHeadersConfig();
  const body = JSON.stringify({ email });

  dispatch({
    type: FORM_SUBMITTED,
  });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/jwt/password/request/reset`,
      body,
      config,
    );

    dispatch({
      type: FORM_FINISHED,
    });

    dispatch(setAlert('Reset link sent! Please check your email for a pin', 'success', 8000));
    history.push('/enter-pin');
  } catch (err) {
    dispatch(setAlert('Invalid email', 'danger'));

    dispatch({
      type: FORM_FINISHED,
    });
  }
};

// Verify Pin
export const verifyPin = (pin, history) => async (dispatch) => {
  const config = Helpers.getAxiosHeadersConfig();
  const body = JSON.stringify({ otp: pin });

  dispatch({
    type: FORM_SUBMITTED,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/jwt/password/otp/verify`,
      body,
      config,
    );

    dispatch({
      type: PIN_SUCCESS,
      payload: res.data.data,
    });

    dispatch(setAlert('PIN verified! Please enter your new password', 'success', 6000));
    history.push('/reset-password');
  } catch (err) {
    dispatch(setAlert('Invalid pin', 'danger'));

    dispatch({
      type: FORM_FINISHED,
    });
  }
};

// Save New Password
export const saveNewPassword =
  (password, password_confirmation, verification_token, history) => async (dispatch) => {
    const config = Helpers.getAxiosHeadersConfig();
    const body = JSON.stringify({ password, password_confirmation, verification_token });

    dispatch({
      type: FORM_SUBMITTED,
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/password/reset`, body, config);

      dispatch({
        type: FORM_FINISHED,
      });

      dispatch(
        setAlert(
          'Password updated successfully! You can now login using your new password',
          'success',
          6000,
        ),
      );
      history.push('/login');
    } catch (err) {
      dispatch(setAlert('Invalid password, please try again', 'danger'));

      dispatch({
        type: FORM_FINISHED,
      });
    }
  };
