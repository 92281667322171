import React, { useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { ReactComponent as CalendarIcon } from '../../assets/icons/CalendarIcon.svg';
import styles from './PeriodSelect.module.scss';
import PeriodSelectUtils, { IDatePeriod } from './PeriodSelectUtils';

interface IDateOption {
  key: number;
  label: string;
  rangeFunction: () => IDatePeriod;
}

const dateOptions: IDateOption[] = [
  { key: 0, label: 'Last 7 days', rangeFunction: PeriodSelectUtils.last7Days },
  { key: 1, label: 'Last 28 days', rangeFunction: PeriodSelectUtils.last28Days },
  { key: 2, label: 'Last 90 days', rangeFunction: PeriodSelectUtils.last90Days },
  { key: 3, label: 'This Year', rangeFunction: PeriodSelectUtils.thisYear },
  { key: 4, label: 'Lifetime', rangeFunction: () => ({ fromDate: null, toDate: null }) },
  {
    key: 5,
    label: `${PeriodSelectUtils.getPreviousYearName()}`,
    rangeFunction: PeriodSelectUtils.previousYear,
  },
  {
    key: 6,
    label: `${PeriodSelectUtils.getBeforeLastYearName()}`,
    rangeFunction: PeriodSelectUtils.beforeLastYear,
  },
  {
    key: 7,
    label: `${PeriodSelectUtils.getPreviousMonthName()}`,
    rangeFunction: PeriodSelectUtils.previousMonth,
  },
  {
    key: 8,
    label: `${PeriodSelectUtils.getBeforeLastMonthName()}`,
    rangeFunction: PeriodSelectUtils.beforeLastMonth,
  },
  {
    key: 9,
    label: `${PeriodSelectUtils.getBefore3MonthName()}`,
    rangeFunction: PeriodSelectUtils.before3Month,
  },
  // { key: 10, label: 'Custom', rangeFunction: PeriodSelectUtils.last7Days },
];

interface IPeriodSelectProps {
  value: number;
  onValueChange?: (value: IDatePeriod) => void;
}

const PeriodSelect: React.FC<IPeriodSelectProps> = ({ value, onValueChange = () => {} }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    dateOptions.find((e) => e.key === value) || dateOptions[3],
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, () => setIsDropdownOpen(false));

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleOptionSelect = (selectedOption: IDateOption) => {
    setSelectedPeriod(selectedOption);
    setIsDropdownOpen(false);

    const calculatedPeriod = selectedOption.rangeFunction?.();
    onValueChange(calculatedPeriod);
  };

  return (
    <div className={styles.periodSelect} onClick={toggleDropdown} ref={componentRef}>
      <CalendarIcon />
      <p>{selectedPeriod.label}</p>
      {isDropdownOpen && (
        <div>
          {dateOptions.map((option) => (
            <div key={option.key} onClick={() => handleOptionSelect(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PeriodSelect;
