import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import { customTickNumberFormatter } from '../../charts/AreaChart/numberFormatter';
import { CustomDot } from '../../charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../charts/LineChart/components/CustomLineChartNumberToolTip';
import CustomLineChart from '../../charts/LineChart/LineChart';
import styles from './TotalViewsChartWidget.module.scss';

const TotalViewsChartWidget = ({ data, loading = false }) => (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <div className={styles.header}>
            <h1>Total Views</h1>
            <ul>
              <li>
                <div className={styles.dot} style={{ color: '#1B2559' }} />
                <span>Total Views</span>
              </li>
              <li>
                <div className={styles.dot} style={{ color: '#A3AED0' }} />
                <span>Unique views</span>
              </li>
            </ul>
          </div>
          <CustomLineChart
            CustomDot={CustomDot}
            CustomToolTip={CustomLineChartNumberToolTip}
            data={data}
            height={250}
            xDataKey="date"
            yCustomTickFormatter={customTickNumberFormatter}
            yDataKey="all"
            yDataKey2="unique"
          />
        </>
      ) : null}
    </div>
  );

export default TotalViewsChartWidget;
