import React from 'react';
import { Link } from 'react-router-dom';

// import { ReactComponent as ArrowDownIcon } from '../../assets/icons/ArrowDownIcon.svg';
// import { ReactComponent as ArrowUpIcon } from '../../assets/icons/ArrowUpIcon.svg';
import { ReactComponent as SeeMoreIcon } from '../../assets/icons/SeeMoreIcon.svg';
import Helpers from '../../utils/Helpers';
import VideoStatusTag from '../videoStatusTag/VideoStatusTag';
import styles from './VideoDetailsTable.module.scss';

const headTitles = [
  { key: 'number', title: '', unOrdered: true },
  { key: 'thumbnail', title: 'Thumbnail' },
  { key: 'title', title: 'Video Title' },
  { key: 'liveDateTime', title: 'Date' },
  { key: 'totalViews', title: 'Total Views' },
  { key: 'isPremium', title: 'Type', unOrdered: true },
  { key: 'averagePlayTime', title: 'Average View Duration' },
  { key: 'likesCount', title: 'Likes' },
  { key: 'commentsCount', title: 'Comments' },
  { key: 'more', title: '', unOrdered: true },
];

// const DEFAULT_ORDERED_COLUMN_INDEX = 3;

// const VideoDetailsTable = ({ data, onColumnOrderChange = () => {} }) => {
const VideoDetailsTable = ({ data }) => 
  // const [orderBy, setOrderBy] = useState({
  //   ...headTitles[DEFAULT_ORDERED_COLUMN_INDEX],
  //   isDescending: true,
  // });

  // const onOrderBy = (column) => {
  //   if (column.unOrdered) return;
  //
  //   const isDescending = column.key === orderBy.key ? !orderBy.isDescending : true;
  //   const newOrderBy = {
  //     ...column,
  //     isDescending,
  //   };
  //   setOrderBy(newOrderBy);
  //   onColumnOrderChange(newOrderBy);
  // };

   (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            {headTitles.map((datum, index) => (
                // <th key={datum.key || index} onClick={() => onOrderBy(datum)}>
                <th key={datum.key || index}>
                  <div>
                    {datum.title}
                    {/* <span> */}
                    {/*  {orderBy?.key === datum.key ? ( */}
                    {/*    orderBy.isDescending ? ( */}
                    {/*      <ArrowDownIcon /> */}
                    {/*    ) : ( */}
                    {/*      <ArrowUpIcon /> */}
                    {/*    ) */}
                    {/*  ) : null} */}
                    {/* </span> */}
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((datum, index) => (
              <tr key={datum.videoId}>
                <td>{index + 1}</td>
                <td>
                  <img className={styles.thumbnail} src={datum.thumbnail} />
                </td>
                <td className={styles.videoTitle}>{datum.title}</td>
                <td>{Helpers.formatDate(datum.liveDateTime)}</td>
                <td>{Helpers.formatNumber(datum.totalViews)}</td>
                <td>
                  <VideoStatusTag isPremium={datum.isPremium} previewTime={datum.previewTime} />
                </td>
                <td>{Helpers.formatSeconds(datum.averagePlayTime)}</td>
                <td>{Helpers.formatNumber(datum.likesCount)}</td>
                <td>{Helpers.formatNumber(datum.commentsCount)}</td>
                <td className={styles.seeMore}>
                  <Link className={styles.seeMoreLink} to={`/dashboard/myVideos/${datum.videoId}`}>
                    <p>see more</p>
                    <span>
                      <SeeMoreIcon />
                    </span>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
;

export default VideoDetailsTable;
