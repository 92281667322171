import axios from 'axios';
import MultipartUploadConfig from '../upload/constants/MultipartUploadConfig';
import Helpers from '../upload/utils/Helpers';
import { setAlert } from './alert';
import { logout } from './auth';
import { removeProgressAlert, setProgressAlert } from './progressAlert';

// TYPES
const VIDEO_UPLOAD_STARTED = 'upload/VIDEO_UPLOAD_STARTED';
const VIDEO_UPLOAD_SUCCESS = 'upload/VIDEO_UPLOAD_SUCCESS';
const VIDEO_UPLOAD_FAIL = 'upload/VIDEO_UPLOAD_FAIL';
const SET_VIDEO_UPLOAD_PROGRESS = 'upload/SET_VIDEO_UPLOAD_PROGRESS';

const THUMBNAIL_UPLOAD_STARTED = 'upload/THUMBNAIL_UPLOAD_STARTED';
const THUMBNAIL_UPLOAD_SUCCESS = 'upload/THUMBNAIL_UPLOAD_SUCCESS';
const THUMBNAIL_UPLOAD_FAIL = 'upload/THUMBNAIL_UPLOAD_FAIL';
const SET_THUMBNAIL_UPLOAD_PERCENTAGE = 'upload/SET_THUMBNAIL_UPLOAD_PERCENTAGE';

const COVER_UPLOAD_STARTED = 'upload/COVER_UPLOAD_STARTED';
const COVER_UPLOAD_SUCCESS = 'upload/COVER_UPLOAD_SUCCESS';
const COVER_UPLOAD_FAIL = 'upload/COVER_UPLOAD_FAIL';
const SET_COVER_UPLOAD_PERCENTAGE = 'upload/SET_COVER_UPLOAD_PERCENTAGE';

const VIDEO_SENT_FOR_REVIEW = 'upload/VIDEO_SENT_FOR_REVIEW';
const VIDEO_SENT_FOR_REVIEW_SUCCESS = 'upload/VIDEO_SENT_FOR_REVIEW_SUCCESS';
const VIDEO_SENT_FOR_REVIEW_FAIL = 'upload/VIDEO_SENT_FOR_REVIEW_FAIL';

const SET_VIDEO_PARTS = 'upload/SeT_VIDEO_PARTS';
const SET_UPLOADED_PARTS = 'upload/SET_UPLOADED_PARTS';
const SET_ACTIVE_CONNECTIONS = 'upload/SET_ACTIVE_CONNECTIONS';
const SET_PROGRESS_CACHE = 'upload/SET_PROGRESS_CACHE';
const SET_UPLOADED_SIZE = 'upload/SET_UPLOADED_SIZE';
const SET_UPLOAD_DATA = 'upload/SET_UPLOAD_DATA';
const SET_IS_INCOMPLETE_UPLOAD_EXISTS = 'upload/SET_IS_INCOMPLETE_UPLOAD_EXISTS';

const UPLOAD_PART_STARTED = 'upload/UPLOAD_PART_STARTED';
const UPLOAD_PART_FAIL = 'upload/UPLOAD_PART_FAIL';
const UPLOAD_PART_SUCCESS = 'upload/UPLOAD_PART_SUCCESS';

const RESET_UPLOAD_STATE = 'upload/RESET_UPLOAD_STATE';
const RESET_VIDEO_UPLOAD = 'upload/RESET_VIDEO_UPLOAD';

const SET_VIDEO_FILE = 'upload/SET_VIDEO_FILE';
const SET_VIDEO_PREVIEW_FILE = 'upload/SET_VIDEO_PREVIEW_FILE';
const SET_COVER_FILE = 'upload/SET_COVER_FILE';
const SET_THUMBNAIL_FILE = 'upload/SET_THUMBNAIL_FILE';

const SET_UPLOAD_CANCEL_TOKEN = 'upload/SET_UPLOAD_CANCEL_TOKEN';

// INITIAL STATE
const initialState = {
  video: {
    file: null,
    id: 2,
    name: '',
    url: '',
    s3originalUrl: '',
    progress: 0,
    loading: false,
    error: '',
    done: false,
    previewFile: null,
    preview: null,
    uploadInfo: null,
  },
  cover: {
    file: null,
    name: '',
    url: '',
    progress: 0,
    loading: false,
    error: '',
    done: false,
    preview: null,
  },
  thumbnail: {
    file: null,
    name: '',
    url: '',
    progress: 0,
    loading: false,
    error: '',
    done: false,
    preview: null,
  },
  submit: { loading: false, done: false },

  isVideoLoading: false,
  isCoverLoading: false,
  isThumbnailLoading: false,

  isAborted: false,
  uploadedSize: 0,
  progressCache: {},
  activeConnections: {},
  parts: [],
  uploadedParts: [],
  uploadingParts: [],

  fileId: null,
  fileKey: null,

  isIncompleteUploadExists: false,
  uploadCancelToken: null,
};

// REDUCER

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_UPLOAD_CANCEL_TOKEN:
      return {
        ...state,
        uploadCancelToken: payload,
      };
    case SET_VIDEO_FILE:
      return {
        ...state,
        video: { ...state.video, done: false, file: payload, isEditing: state.video.file },
      };
    case SET_VIDEO_PREVIEW_FILE:
      return {
        ...state,
        video: { ...state.video, previewFile: payload, preview: URL.createObjectURL(payload) },
      };
    case SET_THUMBNAIL_FILE:
      return {
        ...state,
        thumbnail: { ...state.thumbnail, file: payload, preview: URL.createObjectURL(payload) },
      };
    case SET_COVER_FILE:
      return {
        ...state,
        cover: { ...state.cover, file: payload, preview: URL.createObjectURL(payload) },
      };
    case VIDEO_UPLOAD_STARTED:
      return {
        ...state,
        video: { ...state.video, loading: true, done: false, progress: 0 },
        isVideoLoading: true,
      };
    case SET_PROGRESS_CACHE: {
      return {
        ...state,
        progressCache: payload.progressCache,
      };
    }
    case SET_IS_INCOMPLETE_UPLOAD_EXISTS:
      return {
        ...state,
        video: { ...state.video, progress: 0 },
        uploadedSize: initialState.uploadedSize,
        progressCache: initialState.progressCache,
        activeConnections: initialState.activeConnections,
        isIncompleteUploadExists: payload,
      };
    case SET_UPLOAD_DATA: {
      return {
        ...state,
        fileId: payload.fileId,
        fileKey: payload.fileKey,
      };
    }
    case RESET_UPLOAD_STATE:
      return {
        ...initialState,
      };
    case RESET_VIDEO_UPLOAD:
      return {
        ...state,
        video: {
          ...initialState.video,
        },
        isVideoLoading: false,
      };
    case SET_UPLOADED_SIZE:
      return {
        ...state,
        uploadedSize: payload.uploadedSize,
      };
    case UPLOAD_PART_STARTED:
      return {
        ...state,
        uploadingParts: [...state.uploadingParts, payload.partInfo],
      };
    case SET_ACTIVE_CONNECTIONS:
      return {
        ...state,
        activeConnections: payload.activeConnections,
      };
    case UPLOAD_PART_SUCCESS:
      const activeConnectionsSuccess = state.activeConnections;
      delete activeConnectionsSuccess[payload.partInfo.PartNumber];
      return {
        ...state,
        parts: state.parts.filter((part) => part.PartNumber !== payload.partInfo.PartNumber),
        uploadedParts: [...state.uploadedParts, payload.partInfo],
        uploadingParts: state.uploadingParts.filter(
          (uploadingPart) => uploadingPart.PartNumber !== payload.partInfo.PartNumber,
        ),
        activeConnections: { ...activeConnectionsSuccess },
      };
    case UPLOAD_PART_FAIL:
      const activeConnectionsFail = state.activeConnections;
      delete activeConnectionsFail[payload.partInfo.PartNumber];
      return {
        ...state,
        uploadingParts: state.uploadingParts.filter(
          (uploadingPart) => uploadingPart.PartNumber !== payload.partInfo.PartNumber,
        ),
        activeConnections: { ...activeConnectionsFail },
      };
    case SET_UPLOADED_PARTS:
      return {
        ...state,
        uploadedParts: payload.uploadedParts,
      };
    case SET_VIDEO_PARTS:
      return {
        ...state,
        parts: payload.parts,
      };
    case VIDEO_UPLOAD_SUCCESS:
      return {
        ...state,
        video: {
          ...state.video,
          id: payload.id,
          loading: false,
          done: true,
          url: payload.url,
          s3originalUrl: payload.s3originalUrl,
          progress: 0,
        },
        isVideoLoading: false,
      };
    case VIDEO_UPLOAD_FAIL:
      return {
        ...state,
        video: { ...state.video, id: null, loading: false, done: false, progress: 0 },
        isVideoLoading: false,
      };

    case SET_VIDEO_UPLOAD_PROGRESS:
      return {
        ...state,
        video: {
          ...state.video,
          progress: payload.percentage,
          uploadInfo: payload.uploadInfo,
        },
      };

    case THUMBNAIL_UPLOAD_STARTED:
      return {
        ...state,
        thumbnail: { ...state.thumbnail, done: false, progress: 0, loading: true },
        isThumbnailLoading: true,
      };
    case THUMBNAIL_UPLOAD_SUCCESS:
      return {
        ...state,
        thumbnail: {
          ...state.thumbnail,
          loading: false,
          done: true,
          url: payload.thumbnail,
          progress: 0,
        },
        isThumbnailLoading: false,
      };
    case THUMBNAIL_UPLOAD_FAIL:
      return {
        ...state,
        thumbnail: { ...state.thumbnail, loading: false, done: false, progress: 0 },
        isThumbnailLoading: false,
      };
    case SET_THUMBNAIL_UPLOAD_PERCENTAGE:
      return {
        ...state,
        thumbnail: { ...state.thumbnail, progress: payload },
      };
    case COVER_UPLOAD_STARTED:
      return {
        ...state,
        cover: { ...state.cover, done: false, progress: 0, loading: true },
        isCoverLoading: true,
      };
    case COVER_UPLOAD_SUCCESS:
      return {
        ...state,
        cover: { ...state.cover, loading: false, done: true, url: payload.cover, progress: 0 },
        isCoverLoading: false,
      };
    case COVER_UPLOAD_FAIL:
      return {
        ...state,
        cover: { ...state.cover, loading: false, done: false, progress: 0 },
        isCoverLoading: false,
      };

    case SET_COVER_UPLOAD_PERCENTAGE:
      return {
        ...state,
        cover: { ...state.cover, progress: payload },
      };

    case VIDEO_SENT_FOR_REVIEW:
      return {
        ...state,
        submit: { ...state.submit, loading: true },
      };
    case VIDEO_SENT_FOR_REVIEW_SUCCESS:
      return {
        ...initialState,
        submit: { ...state.submit, loading: false, done: true },
      };
    case VIDEO_SENT_FOR_REVIEW_FAIL:
      return {
        ...state,
        submit: { ...state.submit, loading: false, done: false },
      };
    default:
      return state;
  }
}

// ACTIONS

export const UploadVideoStart = (history) => async (dispatch, getState) => {
  const {
    auth: {
      user: { id: userId },
    },
    upload: {
      video: { file: videoFile, uploadInfo },
      uploadedSize,
    },
  } = getState();

  const uploadCancelToken = axios.CancelToken.source();
  dispatch({
    type: VIDEO_UPLOAD_STARTED,
  });
  dispatch({
    type: SET_UPLOAD_CANCEL_TOKEN,
    payload: uploadCancelToken,
  });

  dispatch(
    setProgressAlert(
      'alert',
      'Please Wait...',
      "We're currently uploading your video. To ensure a successful upload, please avoid navigating away from this page until the process is complete. Thank you for your patience.",
    ),
  );

  const fileName = videoFile.name;

  try {
    const uploadInfoResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/uploads/uploadInfo`,
        { fileName },
        { cancelToken: uploadCancelToken.token },
      )
      .then((res) => res.data);

    if (uploadInfoResponse.data) {
      const uploadedParts = [];
      const parts = [];
      const {fileId} = uploadInfoResponse.data;
      const {fileKey} = uploadInfoResponse.data;

      let updatedUploadedSize = uploadedSize;

      uploadInfoResponse.data.parts.forEach((part) => {
        if (part.completed) {
          const sentSize = (part.PartNumber - 1) * MultipartUploadConfig.CHUNK_SIZE;
          const chunk = videoFile.slice(sentSize, sentSize + MultipartUploadConfig.CHUNK_SIZE);
          updatedUploadedSize += chunk.size;
          uploadedParts.push(part);
        } else {parts.push(part);}
      });

      const total = videoFile.size;
      const sent = Math.min(updatedUploadedSize, total);
      const percentage = Math.round((sent / total) * 100);

      dispatch({
        type: SET_VIDEO_UPLOAD_PROGRESS,
        payload: { percentage, uploadInfo },
      });

      dispatch({
        type: SET_UPLOADED_SIZE,
        payload: { uploadedSize: updatedUploadedSize },
      });

      dispatch({
        type: SET_UPLOADED_PARTS,
        payload: { uploadedParts },
      });

      dispatch({
        type: SET_VIDEO_PARTS,
        payload: { parts },
      });

      dispatch({
        type: SET_UPLOAD_DATA,
        payload: { fileId, fileKey },
      });

      dispatch({
        type: SET_IS_INCOMPLETE_UPLOAD_EXISTS,
        payload: true,
      });

      return;
    }

    const initializeResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/uploads/initializeMultipartUpload`,
        { name: fileName },
        { cancelToken: uploadCancelToken.token },
      )
      .then((res) => res.data);

    if (!initializeResponse.data) {
      dispatch({
        type: VIDEO_UPLOAD_FAIL,
      });

      dispatch(
        setProgressAlert(
          'danger',
          'Upload Error',
          'Something went wrong. Please try re-uploading your video again.',
        ),
      );

      return;
    }

    const {fileId} = initializeResponse.data;
    const {fileKey} = initializeResponse.data;

    dispatch({
      type: SET_UPLOAD_DATA,
      payload: { fileId, fileKey },
    });

    const numberOfChunks = Math.ceil(videoFile.size / MultipartUploadConfig.CHUNK_SIZE);

    const preSignedUrlsResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/uploads/getMultipartPreSignedUrls`,
        { fileId, fileKey, numberOfChunks },
        { cancelToken: uploadCancelToken.token },
      )
      .then((res) => res.data);

    if (!preSignedUrlsResponse.data) {
      dispatch({
        type: VIDEO_UPLOAD_FAIL,
      });

      dispatch(
        setProgressAlert(
          'danger',
          'Upload Error',
          'Something went wrong. Please try re-uploading your video again.',
        ),
      );

      return;
    }

    const {parts} = preSignedUrlsResponse.data;
    dispatch({
      type: SET_VIDEO_PARTS,
      payload: { parts },
    });

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/uploads/saveUploadInfo`,
        { fileId, fileKey, fileName, userId, parts },
        { cancelToken: uploadCancelToken.token },
      )
      .then((res) => res.data);

    dispatch(UploadParts(history));
  } catch (error) {
    console.error(error);

    const isRequestCancelled = axios.isCancel(error);

    if (isRequestCancelled) {
      return;
    }

    if (!error.response) {
      dispatch(setProgressAlert('danger', 'Server Error', ''));
      return;
    }

    const isUnauthorized = error.response.data.errors[0].error === 'Unauthorized';

    if (isUnauthorized) {
      dispatch(logout(history, false));
      return;
    }

    dispatch({
      type: VIDEO_UPLOAD_FAIL,
    });

    dispatch(
      setProgressAlert(
        'danger',
        'Upload Error',
        'Something went wrong. Please try re-uploading your video again.',
      ),
    );
  }
};

export const UploadParts = (history) => async (dispatch, getState) => {
  const {
    upload: {
      parts,
      uploadingParts,
      fileKey,
      fileId,
      activeConnections,
      video: { isEditing },
    },
  } = getState();

  let {
    upload: { uploadCancelToken },
  } = getState();

  // In case of continue incomplete upload
  if (!uploadCancelToken) {
    uploadCancelToken = axios.CancelToken.source();
    dispatch({
      type: SET_UPLOAD_CANCEL_TOKEN,
      payload: uploadCancelToken,
    });
  }

  if (!parts.length) {
    const finalizeMultipartUploadBody = {
      fileKey,
      fileId,
    };

    try {
      const finalizeMultipartUploadResponse = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/uploads/finalizeMultipartUpload`,
          finalizeMultipartUploadBody,
          { cancelToken: uploadCancelToken.token },
        )
        .then((res) => res.data);

      if (!finalizeMultipartUploadResponse.data) {
        dispatch({
          type: VIDEO_UPLOAD_FAIL,
        });

        dispatch(
          setProgressAlert(
            'danger',
            'Upload Error',
            'Something went wrong. Please try re-uploading your video again.',
          ),
        );
      }

      dispatch({
        type: VIDEO_UPLOAD_SUCCESS,
        payload: {
          id: finalizeMultipartUploadResponse.data.id,
          url: finalizeMultipartUploadResponse.data.url,
          s3originalUrl: finalizeMultipartUploadResponse.data.original_s3_url,
        },
      });

      dispatch({
        type: SET_VIDEO_UPLOAD_PROGRESS,
        payload: { percentage: 100, uploadInfo: null },
      });

      dispatch(removeProgressAlert());

      if (!isEditing) {
        dispatch(
          setProgressAlert(
            'success',
            '1 / 3 Video Upload complete!',
            'You can now proceed with next files to upload. In the meantime, your video will be available for preview soon so you can review the video uploaded.',
          ),
        );
      }

      return;
    } catch (error) {
      console.error(error);

      const isRequestCancelled = axios.isCancel(error);

      if (isRequestCancelled) {
        return;
      }

      if (!error.response) {
        dispatch(setProgressAlert('danger', 'Server Error', ''));
        return;
      }

      const isUnauthorized = error.response.data.errors[0].error === 'Unauthorized';

      if (isUnauthorized) {
        dispatch(logout(history, false));
        return;
      }

      dispatch({
        type: VIDEO_UPLOAD_FAIL,
      });

      dispatch(
        setProgressAlert(
          'danger',
          'Upload Error',
          'Something went wrong. Please try re-uploading your video again.',
        ),
      );
    }
  }

  const activeConnectionsLength = Object.keys(activeConnections).length;

  if (activeConnectionsLength >= MultipartUploadConfig.MAX_THREADS_QUANTITY) {return;}

  const unUploadedParts = parts.filter(
    (part) => !uploadingParts.some((uploadedPart) => uploadedPart.PartNumber === part.PartNumber),
  );

  unUploadedParts.forEach((part) => {
    dispatch(uploadPart(part, history));
  });
};

export const uploadPart = (partInfo, history) => async (dispatch, getState) => {
  dispatch({
    type: VIDEO_UPLOAD_STARTED,
  });

  const {
    upload: {
      activeConnections,
      progressCache,
      uploadedSize,
      uploadCancelToken,
      video: { file, uploadInfo },
    },
  } = getState();

  const activeConnectionsLength = Object.keys(activeConnections).length;

  if (activeConnectionsLength >= MultipartUploadConfig.MAX_THREADS_QUANTITY) {return;}

  if (file && partInfo) {
    const sentSize = (partInfo.PartNumber - 1) * MultipartUploadConfig.CHUNK_SIZE;
    const chunk = file.slice(sentSize, sentSize + MultipartUploadConfig.CHUNK_SIZE);

    const startTime = new Date().getTime();

    const onUploadProgress = (progressEvent) => {
      if (!file) {return;}

      let updatedUploadedSize = uploadedSize;

      if (['progress', 'error', 'abort'].includes(progressEvent.event.type)) {
        progressCache[partInfo.PartNumber - 1] = progressEvent.loaded;
        dispatch({
          type: SET_PROGRESS_CACHE,
          payload: { progressCache },
        });
      }

      if (progressEvent.event.type === 'uploaded') {
        updatedUploadedSize = updatedUploadedSize + progressCache[partInfo.PartNumber - 1] || 0;
        dispatch({
          type: SET_UPLOADED_SIZE,
          payload: { uploadedSize: updatedUploadedSize },
        });

        delete progressCache[partInfo.PartNumber - 1];
        dispatch({
          type: SET_PROGRESS_CACHE,
          payload: { progressCache },
        });
      }

      const inProgress = Object.values(progressCache).reduce((total, loaded) => total + loaded, 0);

      const uploadedSizeBytes = Math.min(updatedUploadedSize + inProgress, file.size);

      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime; // Time taken for upload
      const uploadSpeedBytesPerSec = (uploadedSizeBytes / elapsedTime) * 1000; // Upload speed in bytes per second

      const remainingTimeSec = (file.size - uploadedSizeBytes) / uploadSpeedBytesPerSec;

      const total = file.size;

      const percentage = Math.round((uploadedSizeBytes / total) * 100);

      dispatch({
        type: SET_VIDEO_UPLOAD_PROGRESS,
        payload: {
          percentage,
          uploadInfo: {
            uploadSpeed: uploadSpeedBytesPerSec,
            remainingTime: remainingTimeSec,
            uploadedSize: uploadedSizeBytes,
          },
        },
      });
    };

    const transformRequest = (data, headers) => {
      delete headers.Authorization;
      return data;
    };

    dispatch({
      type: UPLOAD_PART_STARTED,
      payload: { partInfo },
    });

    dispatch({
      type: SET_ACTIVE_CONNECTIONS,
      payload: {
        activeConnections: { ...activeConnections, [partInfo.PartNumber]: partInfo },
      },
    });

    try {
      const uploadedPartInfoResponse = await axios.put(partInfo.signedUrl, chunk, {
        onUploadProgress,
        transformRequest,
        cancelToken: uploadCancelToken.token,
      });

      const ETag = uploadedPartInfoResponse.headers.etag;

      if (!ETag) {
        dispatch({
          type: VIDEO_UPLOAD_FAIL,
        });

        dispatch(
          setProgressAlert(
            'danger',
            'Upload Error',
            'Something went wrong. Please try re-uploading your video again.',
          ),
        );

        return;
      }

      const fileName = file.name;
      const markUploadedPartBody = {
        fileName,
        PartNumber: partInfo.PartNumber,
        ETag,
      };

      const markUploadedPartResponse = await axios
        .post(`${process.env.REACT_APP_API_URL}/uploads/markUploadedPart`, markUploadedPartBody, {
          cancelToken: uploadCancelToken.token,
        })
        .then((res) => res.data);

      if (!markUploadedPartResponse.data) {
        dispatch({
          type: VIDEO_UPLOAD_FAIL,
        });

        dispatch(
          setProgressAlert(
            'danger',
            'Upload Error',
            'Something went wrong. Please try re-uploading your video again.',
          ),
        );

        return;
      }

      dispatch({
        type: UPLOAD_PART_SUCCESS,
        payload: { partInfo },
      });

      dispatch(UploadParts(history));
    } catch (error) {
      console.error(error);

      // in case of cancellation upload doesn't need to try to re-upload parts
      const isRequestCancelled = axios.isCancel(error);

      if (isRequestCancelled) {
        return;
      }

      if (!error.response) {
        dispatch(setProgressAlert('danger', 'Server Error', ''));
        return;
      }

      const isUnauthorized = error.response.data.errors[0].error === 'Unauthorized';

      if (isUnauthorized) {
        dispatch(logout(history, false));
        return;
      }

      // try to re-upload parts
      setTimeout(() => {
        dispatch({
          type: UPLOAD_PART_FAIL,
          payload: { partInfo },
        });

        const updatedUploadedSize = uploadedSize - progressCache[partInfo.PartNumber - 1] || 0;
        const inProgress = Object.keys(progressCache)
          .map(Number)
          .reduce((memo, id) => (memo += progressCache[id]), 0);
        const sent = Math.min(updatedUploadedSize + inProgress, file.size);
        const total = file.size;
        const percentage = Math.round((sent / total) * 100);

        dispatch({
          type: SET_VIDEO_UPLOAD_PROGRESS,
          payload: { percentage, uploadInfo },
        });

        dispatch(UploadParts(history));
      }, 20000);
    }
  }
};

export const uploadImage = async (formData, uploadCancelToken, dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/video/upload/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      cancelToken: uploadCancelToken.token,
    });

    console.log(res);
  } catch (error) {
    console.error(error);

    const isRequestCancelled = axios.isCancel(error);

    if (isRequestCancelled) {
      return;
    }

    if (!error.response) {
      dispatch(setProgressAlert('danger', 'Server Error', ''));
      return;
    }

    const isUnauthorized = error.response.data.errors[0].error === 'Unauthorized';

    if (isUnauthorized) {
      dispatch(logout(history, false));
      
    }
  }
};

export const resetUploadState = () => async (dispatch, getState) => {
  const {
    upload: { uploadCancelToken },
  } = getState();

  if (uploadCancelToken) {
    uploadCancelToken.cancel();
  }

  dispatch({
    type: RESET_UPLOAD_STATE,
  });
};

export const resetVideoUpload = () => async (dispatch, getState) => {
  const {
    upload: { uploadCancelToken },
  } = getState();

  if (uploadCancelToken) {
    uploadCancelToken.cancel();
  }

  dispatch({
    type: RESET_VIDEO_UPLOAD,
  });
};

export const setIsIncompleteUploadExists = (state) => async (dispatch) => {
  dispatch({
    type: SET_IS_INCOMPLETE_UPLOAD_EXISTS,
    payload: state,
  });
};

export const submitForReview = () => async (dispatch, getState) => {
  const {
    upload: {
      video: { previewFile, file: videoFile, url, s3originalUrl },
      thumbnail: { file: thumbnailFile },
      cover: { file: coverFile },
      uploadCancelToken,
    },
  } = getState();

  const config = { ...Helpers.getAxiosHeadersConfig(), cancelToken: uploadCancelToken.token };

  dispatch({
    type: VIDEO_SENT_FOR_REVIEW,
  });

  try {
    const createVideoPayload = {
      url,
      original_s3_url: s3originalUrl,
      file_name: videoFile.name,
    };
    const createVideoRes = await axios
      .post(`${process.env.REACT_APP_API_URL}/video/create`, createVideoPayload, config)
      .then((res) => res.data);
    const createdVideoId = createVideoRes.data.id;

    const previewFormData = new FormData();
    previewFormData.append('image', previewFile);
    previewFormData.append('name', previewFile.name);
    previewFormData.append('type', 'video_preview');
    previewFormData.append('id', createdVideoId);
    await uploadImage(previewFormData, uploadCancelToken, dispatch);

    const thumbnailFormData = new FormData();
    thumbnailFormData.append('image', thumbnailFile);
    thumbnailFormData.append('name', thumbnailFile.name);
    thumbnailFormData.append('type', 'thumbnail');
    thumbnailFormData.append('id', createdVideoId);
    await uploadImage(thumbnailFormData, uploadCancelToken, dispatch);

    const coverFormData = new FormData();
    coverFormData.append('image', coverFile);
    coverFormData.append('name', coverFile.name);
    coverFormData.append('type', 'cover');
    coverFormData.append('id', createdVideoId);
    await uploadImage(coverFormData, uploadCancelToken, dispatch);

    const submitVideoPayload = {
      id: createdVideoId,
    };
    const submitVideoRes = await axios.post(
      `${process.env.REACT_APP_API_URL}/video/status`,
      submitVideoPayload,
      config,
    );

    dispatch({
      type: VIDEO_SENT_FOR_REVIEW_SUCCESS,
      payload: submitVideoRes.data,
    });

    dispatch(
      setProgressAlert(
        'info',
        'Content Sent for Review',
        'The VUZ Content Team is now reviewing your content. We will let you know by Email regarding the approval status of your content. Thank you!',
      ),
    );
  } catch (error) {
    console.error(error);

    const isRequestCancelled = axios.isCancel(error);

    if (isRequestCancelled) {
      return;
    }

    if (!error.response) {
      dispatch(setProgressAlert('danger', 'Server Error', ''));
      return;
    }

    const isUnauthorized = error.response.data.errors[0].error === 'Unauthorized';

    if (isUnauthorized) {
      dispatch(logout(history, false));
      return;
    }

    const {message} = error.response.data;

    if (message) {
      dispatch(setAlert(message, 'danger'));
    }

    dispatch({
      type: VIDEO_SENT_FOR_REVIEW_FAIL,
    });
  }
};

export const setVideoFile = (file) => async (dispatch) => {
  dispatch({
    type: SET_VIDEO_FILE,
    payload: file,
  });
};

export const setVideoPreviewFile = (file) => async (dispatch) => {
  dispatch({
    type: SET_VIDEO_PREVIEW_FILE,
    payload: file,
  });
};

export const setCoverFile = (file) => async (dispatch) => {
  dispatch({
    type: SET_COVER_FILE,
    payload: file,
  });
};

export const setThumbnailFile = (file) => async (dispatch) => {
  dispatch({
    type: SET_THUMBNAIL_FILE,
    payload: file,
  });
};
