import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../layout/PrivateRoute';
import Analytics from '../../pages/Analytics/Analytics';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Earnings from '../../pages/Earnings/Earnings';
import SingleVideo from '../../pages/myVideos/components/singleVideo/SingleVideo';
import MyVideos from '../../pages/myVideos/MyVideos';

export const Routes = () => (
  <Switch>
    <PrivateRoute component={Dashboard} exact path="/dashboard" />
    <PrivateRoute component={Analytics} exact path="/dashboard/analytics" />
    <PrivateRoute component={MyVideos} exact path="/dashboard/myVideos" />
    <PrivateRoute component={SingleVideo} path="/dashboard/myVideos/:id" />
    <PrivateRoute component={Earnings} exact path="/dashboard/earnings" />
  </Switch>
);
