import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import axios from 'axios';
import classNames from 'classnames';
import { removeProgressAlert, setProgressAlert } from '../../../../store/progressAlert';
import {
  resetUploadState,
  resetVideoUpload,
  setCoverFile,
  setIsIncompleteUploadExists,
  setThumbnailFile,
  setVideoFile,
  setVideoPreviewFile,
  submitForReview,
  UploadParts,
  UploadVideoStart,
} from '../../../../store/upload';
import Helpers from '../../../utils/Helpers';
import ProgressAlert from '../../layout/ProgressAlert/ProgressAlert';
import styles from './Upload.module.scss';

const THUMBNAIL_WIDTH = 574;
const THUMBNAIL_HEIGHT = 444;
const THUMBNAIL_MAX_FILE_SIZE = 250000;
const COVER_WIDTH = 905;
const COVER_HEIGHT = 760;
const COVER_MAX_FILE_SIZE = 250000;

const Upload = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { video, thumbnail, cover, isVideoLoading, submit, isIncompleteUploadExists } = useSelector(
    (state) => state.upload,
  );

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {return;}

    dispatch(resetUploadState());

    history.push('/login');
  }, [isAuthenticated]);

  const uploadElementRef = useRef();
  const [uploadElementWidth, setUploadElementWidth] = useState(0);

  useEffect(() => {
    setUploadElementWidth(uploadElementRef.current?.clientWidth);
  }, [uploadElementRef]);

  const videoFileInputRef = useRef(null);
  const thumbnailFileInputRef = useRef(null);
  const coverFileInputRef = useRef(null);

  const isUploadReadyForReview = useMemo(
    () => video.url && video.done && thumbnail.file && cover.file,
    [video, thumbnail, cover],
  );

  useEffect(
    () => () => {
      dispatch(removeProgressAlert());
    },
    [],
  );

  const onChangeVideo = (event) => {
    const videoFile = event.target.files[0];

    if (!videoFile) {return;}

    if (videoFile.type !== 'video/mp4') {
      dispatch(setProgressAlert('danger', 'Invalid file format', 'File can be only mp4 format.'));
      return;
    }

    dispatch(setVideoFile(videoFile));
  };

  const onOpenVideoFileDialog = (event) => {
    event.preventDefault();

    videoFileInputRef.current.click();
  };

  useEffect(() => {
    if (window.innerWidth < 1024) {
      history.push('/uploads');
    }
  }, [history]);

  useEffect(() => {
    if (!video.file || video.loading) {return;}

    const _CANVAS = document.querySelector('#image-canvas');
    const _CTX = _CANVAS.getContext('2d');
    const _VIDEO = document.querySelector('#main-video');

    document
      .querySelector('#main-video source')
      .setAttribute('src', URL.createObjectURL(video.file));

    _VIDEO.load();
    _VIDEO.currentTime = 0.001;

    _VIDEO.addEventListener('loadedmetadata', () => {
      _CANVAS.width = _VIDEO.videoWidth;
      _CANVAS.height = _VIDEO.videoHeight;
    });

    _VIDEO.addEventListener('canplay', () => {
      _CTX.drawImage(_VIDEO, 0, 0, _VIDEO.videoWidth, _VIDEO.videoHeight);

      _CANVAS.toBlob((blob) => {
        const previewFile = new File([blob], 'videoPreview.jpg', { type: 'image/jpeg' });

        dispatch(setVideoPreviewFile(previewFile));
      }, 'image/jpeg');
    });
  }, [video.file]);

  useEffect(() => {
    if (!video.file) {
      videoFileInputRef.current.value = '';
      return;
    }

    if (video.loading || video.done) {return;}

    dispatch(UploadVideoStart(history));
  }, [video.file]);

  const onChangeThumbnail = async (e) => {
    const thumbnailFile = e.target.files[0];

    if (!thumbnailFile) {return;}

    if (!['image/jpeg', 'image/jpg', 'image/png'].includes(thumbnailFile.type)) {
      dispatch(
        setProgressAlert(
          'danger',
          'Invalid file format',
          'File can be only jpg, jpeg, png format.',
        ),
      );
      return;
    }

    if (thumbnailFile.size > THUMBNAIL_MAX_FILE_SIZE) {
      dispatch(
        setProgressAlert('danger', 'Invalid file size', 'File can only be less than 250 kb.'),
      );
      return;
    }

    const imageDimensions = await Helpers.imageSize(URL.createObjectURL(thumbnailFile));

    if (imageDimensions.width !== THUMBNAIL_WIDTH || imageDimensions.height !== THUMBNAIL_HEIGHT) {
      dispatch(
        setProgressAlert(
          'danger',
          'Invalid file dimension',
          `File can only be ${THUMBNAIL_WIDTH}x${THUMBNAIL_HEIGHT} px`,
        ),
      );
      return;
    }

    const isEditing = thumbnail.file;

    dispatch(setThumbnailFile(thumbnailFile));
    dispatch(removeProgressAlert());

    if (!isEditing) {
      dispatch(
        setProgressAlert(
          'success',
          '2 / 3 Thumbnail (Horizontal) Upload complete!',
          'Almost there! You can now upload the last file to upload. Also, you can review the file and re-upload if needed through clicking the “Edit” button.',
        ),
      );
    }
  };

  const onOpenThumbnailFileDialog = (event) => {
    event.preventDefault();

    thumbnailFileInputRef.current.click();
  };

  const onChangeCover = async (e) => {
    const coverFile = e.target.files[0];

    if (!coverFile) {return;}

    if (!['image/jpeg', 'image/jpg', 'image/png'].includes(coverFile.type)) {
      dispatch(
        setProgressAlert(
          'danger',
          'Invalid file format',
          'File can be only jpg, jpeg, png format.',
        ),
      );
      return;
    }

    if (coverFile.size > COVER_MAX_FILE_SIZE) {
      dispatch(
        setProgressAlert('danger', 'Invalid file size', 'File can only be less than 250 kb.'),
      );
      return;
    }

    const imageDimensions = await Helpers.imageSize(URL.createObjectURL(coverFile));

    if (imageDimensions.width !== COVER_WIDTH || imageDimensions.height !== COVER_HEIGHT) {
      dispatch(
        setProgressAlert(
          'danger',
          'Invalid file dimension',
          `File can only be ${COVER_WIDTH}x${COVER_HEIGHT} px`,
        ),
      );
      return;
    }

    const isEditing = cover.file;

    dispatch(setCoverFile(coverFile));
    dispatch(removeProgressAlert());

    if (!isEditing) {
      dispatch(
        setProgressAlert(
          'success',
          '3 / 3 Cover (Vertical) Upload complete!',
          'Congratulations! All files were uploaded successfully. Please review all the files you uploaded. Once reviewed, you can now proceed with Uploading Content by clicking on “Send for Review” below.',
        ),
      );
    }
  };

  const onOpenCoverFileDialog = (event) => {
    event.preventDefault();

    coverFileInputRef.current.click();
  };

  const onSubmitVideoForReview = (e) => {
    e.preventDefault();
    dispatch(submitForReview());
  };

  const onRejectIncompleteUpload = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/uploads/deleteUploadInfo`, {
          fileName: video.file.name,
        })
        .then((res) => res.data);
      dispatch(setIsIncompleteUploadExists(false));
      // dispatch(resetUploadState());
      dispatch(UploadVideoStart(history));
    } catch (error) {
      console.error(error);
    }
  };

  const onContinueIncompleteUpload = async (history) => {
    dispatch(setIsIncompleteUploadExists(false));
    dispatch(UploadParts(history));
  };

  const onLeavePage = (onConfirm) => {
    dispatch(resetUploadState());
    dispatch(removeProgressAlert());
    onConfirm();
  };

  const onCancelUpload = () => {
    dispatch(resetVideoUpload());
    dispatch(removeProgressAlert());
  };

  return (
    <>
      <section className={classNames('upload-section', styles.uploadSection)}>
        <h1 className={styles.uploadPageTitle}>Upload Content</h1>
        <div className={styles.upload} ref={uploadElementRef}>
          <form onSubmit={onOpenVideoFileDialog}>
            <input
              className="hidden"
              id="video-file"
              onChange={onChangeVideo}
              ref={videoFileInputRef}
              type="file"
              value=""
            />
            <div className={styles.stepDiv}>
              <div className={styles.stepTitle}>
                <div>1</div>
                <span>360 Video</span>
              </div>
              <div className={styles.videoBoxOuterDiv}>
                {!video.file && (
                  <div className={styles.videoBoxInnerDiv}>
                    <div className={styles.stepIconWrapper}>
                      <img
                        alt="Video icon"
                        className={classNames('img-fluid', styles.videoBoxImg)}
                        src="/assets/icons/video.svg"
                      />
                    </div>
                    <span className={styles.infoText}>
                      File must be in <span className={styles.bold}>.mp4 format</span> only
                    </span>
                  </div>
                )}
                {video.file && (
                  <div className={styles.filePreview}>
                    <span>
                      File name: <strong>{video.file.name}</strong>
                    </span>
                    <div className={styles.previewImageContainer}>
                      <img alt="Video" className={styles.previewImage} src={video.preview} />
                      {isVideoLoading && (
                        <img
                          alt="Loading icon"
                          className={styles.loader}
                          src="/assets/icons/loader.svg"
                        />
                      )}
                    </div>
                    <button
                      className={classNames(
                        styles.videoEditBtn,
                        isVideoLoading && 'visibility-hidden',
                      )}
                      onClick={onOpenVideoFileDialog}
                    >
                      <img alt="Edit" src="/assets/icons/edit.svg" />
                      <span>Edit</span>
                    </button>
                  </div>
                )}
              </div>
              <div className={styles.uploadBtnContainer}>
                {!video.file && <button className={styles.stepBtn}>Select file</button>}
                {isVideoLoading && (
                  <div className={styles.uploadProgress}>
                    <span>Uploading {video.progress}%</span>
                    <div className={styles.progressContainer}>
                      <div className={styles.progressBarContainer}>
                        <ProgressBar animated now={video.progress} />
                        <button className={styles.cancelUploadBtn} onClick={onCancelUpload}>
                          <img alt="Cancel upload" src="/assets/icons/close.svg" />
                        </button>
                      </div>
                      {video.uploadInfo && video?.file && (
                        <span className={styles.uploadInfo}>
                          {Helpers.formatUploadInfo(video.file.size, video.uploadInfo)}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
          <form>
            <input
              className="hidden"
              id="thumbnail-file"
              onChange={onChangeThumbnail}
              ref={thumbnailFileInputRef}
              type="file"
              value=""
            />
            <div className={styles.stepDiv}>
              <div className={styles.stepTitle}>
                <div>2</div>
                <span>Thumbnail</span>
              </div>
              <div className={styles.videoBoxOuterDiv}>
                {!thumbnail.file && (
                  <div className={styles.videoBoxInnerDiv}>
                    <div className={styles.stepIconWrapper}>
                      <img
                        alt="Thumbnail icon"
                        className={classNames('img-fluid', styles.videoBoxImg)}
                        src="/assets/icons/cover.svg"
                      />
                    </div>
                    <span className={styles.infoText}>
                      Image dimension:{' '}
                      <span className={styles.bold}>
                        {THUMBNAIL_WIDTH}x{THUMBNAIL_HEIGHT} px
                      </span>
                      <br />
                      Image format: <span className={styles.bold}>jpg, jpeg</span> and{' '}
                      <span className={styles.bold}>png</span>
                      <br />
                      Max image file size is <span className={styles.bold}>250 kb</span>
                    </span>
                  </div>
                )}
                {thumbnail.file && (
                  <div className={styles.filePreview}>
                    <span>
                      File name: <strong>{thumbnail.file.name}</strong>
                    </span>
                    <div className={styles.previewImageContainer}>
                      <img
                        alt="Thumbnail"
                        className={styles.previewImage}
                        src={thumbnail.preview}
                      />
                    </div>
                    <button className={styles.videoEditBtn} onClick={onOpenThumbnailFileDialog}>
                      <img alt="Edit" src="/assets/icons/edit.svg" />
                      <span>Edit</span>
                    </button>
                  </div>
                )}
              </div>

              <div className={styles.uploadBtnContainer}>
                {!thumbnail.file && (
                  <button
                    className={styles.stepBtn}
                    disabled={!video.url}
                    onClick={onOpenThumbnailFileDialog}
                  >
                    Select file
                  </button>
                )}
                {/* { */}
                {/*  isThumbnailLoading && ( */}
                {/*    <div className='upload-progress'> */}
                {/*      <span>Uploading {thumbnail.progress}%</span> */}
                {/*      <div className='progress-container'> */}
                {/*        <ProgressBar animated now={thumbnail.progress} /> */}
                {/*      </div> */}
                {/*    </div> */}
                {/*  ) */}
                {/* } */}
              </div>
            </div>
          </form>
          <form>
            <input
              className="hidden"
              id="cover-file"
              onChange={onChangeCover}
              ref={coverFileInputRef}
              type="file"
              value=""
            />
            <div className={styles.stepDiv}>
              <div className={styles.stepTitle}>
                <div>3</div>
                <span>Cover</span>
              </div>
              <div className={styles.videoBoxOuterDiv}>
                {!cover.file && (
                  <div className={styles.videoBoxInnerDiv}>
                    <div className={styles.stepIconWrapper}>
                      <img
                        alt="Cover icon"
                        className={classNames('img-fluid', styles.videoBoxImg)}
                        src="/assets/icons/cover.svg"
                      />
                    </div>
                    <span className={styles.infoText}>
                      Image dimension:{' '}
                      <span className={styles.bold}>
                        {COVER_WIDTH}x{COVER_HEIGHT} px
                      </span>
                      <br />
                      Image format: <span className={styles.bold}>jpg, jpeg</span> and{' '}
                      <span className={styles.bold}>png</span>
                      <br />
                      Max image file size is <span className={styles.bold}>250 kb</span>
                    </span>
                  </div>
                )}
                {cover.file && (
                  <div className={styles.filePreview}>
                    <span>
                      File name: <strong>{cover.file.name}</strong>
                    </span>
                    <div className={styles.previewImageContainer}>
                      <img alt="Thumbnail" className={styles.previewImage} src={cover.preview} />
                    </div>
                    <button className={styles.videoEditBtn} onClick={onOpenCoverFileDialog}>
                      <img alt="Edit" src="/assets/icons/edit.svg" />
                      <span>Edit</span>
                    </button>
                  </div>
                )}
              </div>

              <div className={styles.uploadBtnContainer}>
                {!cover.file && (
                  <button
                    className={styles.stepBtn}
                    disabled={!thumbnail.file}
                    onClick={onOpenCoverFileDialog}
                  >
                    Select file
                  </button>
                )}
                {/* { */}
                {/*  isCoverLoading && ( */}
                {/*    <div className='upload-progress'> */}
                {/*      <span>Uploading {cover.progress}%</span> */}
                {/*      <div className='progress-container'> */}
                {/*        <ProgressBar animated now={cover.progress} /> */}
                {/*      </div> */}
                {/*    </div> */}
                {/*  ) */}
                {/* } */}
              </div>
            </div>
          </form>
        </div>
        <div className={styles.progressAlertWrapper}>
          <ProgressAlert forceWidth={uploadElementWidth} />
        </div>
        {isUploadReadyForReview && (
          <form className="text-center" onSubmit={onSubmitVideoForReview}>
            <button className={styles.submitForReviewBtn} disabled={submit.loading}>
              {submit.loading && <i className="fas fa-circle-notch fa-spin sm-icon-margin-1" />}
              {!submit.loading && 'Send for Review'}
            </button>
          </form>
        )}
      </section>

      {isIncompleteUploadExists && (
        <div className={styles.uploadInfoModalOverlay}>
          <div className={styles.uploadInfoModal}>
            <h4>You have incomplete upload of current file, do you want to continue?</h4>
            <span>In case of cancellation upload progress will be lost</span>
            <div className={styles.controls}>
              <button className={styles.secondary} onClick={onRejectIncompleteUpload}>
                Cancel
              </button>
              <button onClick={() => onContinueIncompleteUpload(history)}>Continue</button>
            </div>
          </div>
        </div>
      )}

      <NavigationPrompt
        when={(currentLocation, nextLocation) =>
          isAuthenticated && isVideoLoading && currentLocation.pathname !== nextLocation.pathname
        }
      >
        {({ onConfirm, onCancel }) => (
          <div className={styles.uploadInfoModalOverlay}>
            <div className={styles.uploadInfoModal}>
              <h4>Leaving the page will cancel your upload progress</h4>
              <span>Do you wish to continue leaving the page?</span>
              <div className={styles.controls}>
                <button className={styles.secondary} onClick={() => onLeavePage(onConfirm)}>
                  Leave page
                </button>
                <button onClick={onCancel}>Continue upload</button>
              </div>
            </div>
          </div>
        )}
      </NavigationPrompt>

      <video controls id="main-video">
        <source type="video/mp4" />
      </video>
      <canvas className="hidden" id="image-canvas" />
    </>
  );
};

export default Upload;
