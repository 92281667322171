import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

const CustomBarChart = ({
  data,
  width,
  height = 400,
  xDataKey = 'name',
  yDataKey = 'value',
  strokeColor = '#074DFF',
  xThickColor = '#A3AED0',
  cellColor = '#E9EDF7',
  hoveredCellColor = '#074DFF',
  CustomLabel = () => {},
  CustomTooltip = () => {},
}) => {
  const [focusedBar, setFocusedBar] = useState(null);
  const max = Math.max(...data.map((item) => item.value));

  return (
    <ResponsiveContainer height={height} width={width}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
        onMouseMove={(state) => {
          setFocusedBar(state.isTooltipActive ? state.activeTooltipIndex : null);
        }}
      >
        <XAxis
          axisLine={false}
          dataKey={xDataKey}
          tick={{
            fill: xThickColor,
            fontSize: 12,
            fontFamily: 'Inter, sans-serif',
          }}
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Bar barSize={34} dataKey={yDataKey} radius={[8, 8, 8, 8]}>
          {data.map((entry, index) => (
            <Cell
              fill={focusedBar === index ? hoveredCellColor : cellColor}
              key={`cell-${index}`}
              onMouseEnter={() => {
                setFocusedBar(index);
              }}
              onMouseLeave={() => {
                setFocusedBar(null);
              }}
            />
          ))}
        </Bar>
        <ReferenceLine
          isFront
          label={<Label content={(viewBox) => CustomLabel({ viewBox, max })} />}
          onClick={() => {
            setFocusedBar(1);
          }}
          stroke={strokeColor}
          strokeDasharray="3 3"
          y={max}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
