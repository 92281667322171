export interface IEarningChart {
  date: Date | string;
  earning: number;
}

export interface IAudioRoomsEarning {
  id: number;
  roomName: string;
  date: Date | string;
  gifts: number;
  users: number;
  points: number;
  joinsEarnings: number;
  giftsEarnings: number;
}

export interface IAudioRoomsEarnings {
  chart: IEarningChart[];
  earnings: IAudioRoomsEarning[];
}

export interface IPromoCodeEarning {
  id: number;
  date: Date | string;
  subscribers: number;
  earnings: number;
}

export interface IPromoCodeEarnings {
  chart: IEarningChart[];
  earnings: IPromoCodeEarning[];
}

export interface IRaffleDrawEarning {
  id: number;
  name: string;
  startDate: Date | string;
  endDate: Date | string;
  entries: number;
  earnings: number;
}

export interface IRaffleDrawEarnings {
  chart: IEarningChart[];
  earnings: IRaffleDrawEarning[];
}

export interface IShopSalesEarning {
  id: number;
  name: string;
  date: Date | string;
  unitsSold: number;
  earnings: number;
}

export interface IShopSalesEarnings {
  chart: IEarningChart[];
  earnings: IShopSalesEarning[];
}

export interface IVideoEarning {
  commentsCount: number;
  earning: number;
  image?: string | null;
  isPremium: number;
  likesCount: number;
  liveDateTime: Date | string;
  previewTime: number;
  sharesCount: number;
  thumbnail?: string | null;
  title: string;
  totalViews: number;
  videoId: number;
}

export interface IVideoEarnings {
  chart: IEarningChart[];
  earnings: IVideoEarning[];
}

export interface IVideoRoomsEarning {
  id: number;
  roomName: string;
  date: Date | string;
  gifts: number;
  users: number;
  points: number;
  joinsEarnings: number;
  giftsEarnings: number;
}

export interface IVideoRoomsEarnings {
  chart: IEarningChart[];
  earnings: IVideoRoomsEarning[];
}

export interface IEarningsMilestones {
  actualClicks: number;
  actualInstalls: number;
  createdAt: string;
  fetchedDataFromBranch: number;
  id: number;
  influencerId: number;
  isDeleted: number;
  milestone1Bonus: number;
  milestone1Clicks: number;
  milestone1Cost: number;
  milestone1Users: string;
  milestone1UsersMax: number;
  milestone1UsersMin: number;
  milestone2Bonus: number;
  milestone2Clicks: number;
  milestone2Cost: number;
  milestone2Users: string;
  milestone2UsersMax: number;
  milestone2UsersMin: number;
  milestone3Bonus: number;
  milestone3Clicks: number;
  milestone3Cost: number;
  milestone3Users: string;
  milestone3UsersMax: number;
  milestone3UsersMin: number;
  month: string;
}

export interface IInfluencerInfo {
  id?: number;
  avatar?: string;
  name?: string;
  videoViewsCount?: number;
  videosCount: number;
}

export interface IInfluencerEarningsConfiguration {
  videoViewsEarningEnabled: boolean;
  audioRoomsEarningEnabled: boolean;
  shopRevenueEnabled: boolean;
  raffleDrawEarningEnabled: boolean;
  promoCodeEarningEnabled: boolean;
  videoRoomsEarningEnabled: boolean;
  vuzPayEarningsEnabled: boolean;
  vuzPayDeepLink?: string;
}

export interface ITotalEarnings {
  configuration: IInfluencerEarningsConfiguration | null;
  videosEarnings: IVideoEarnings | null;
  audioRoomsEarnings: IAudioRoomsEarnings | null;
  shopSalesEarnings: IShopSalesEarnings | null;
  raffleDrawsEarnings: IRaffleDrawEarnings | null;
  promoCodesEarnings: IPromoCodeEarnings | null;
  videoRoomsEarnings: IVideoRoomsEarnings | null;
}

export interface ITopVideo {
  id: number;
  earning: number;
  likes: number;
  rating: number;
  shares: number;
  thumbnail?: string | null;
  title: string;
  views: number;
}

export interface ILatestVideo {
  id: number;
  title: string;
  thumbnail: string;
  createDatetime: Date;
  likes: number;
  comments: number;
  views: number;
}

interface IEarning {
  title: string;
  count: number;
  earning: number;
}

export interface IDashboardInitialEarnings {
  name: string;
  value: number;
  extended: {
    videoEarnings?: IEarning;
    voiceRoomsEarnings?: IEarning;
    videoRoomsEarnings?: IEarning;
    shopSalesEarnings?: IEarning;
    raffleDrawEarnings?: IEarning;
    promoCodesEarnings?: IEarning;
  };
}

export interface IVuzPayConversion {
  clicks: number;
  purchases: number;
}

export interface IVuzPayEarning {
  id: number;
  price: number;
  date: Date | string;
  earnings: number;
}

export interface IVuzPayEarnings {
  chart: IEarningChart[];
  earnings: IVuzPayEarning[];
}

export enum EarningsTabs {
  VideoEarnings = 'videoEarnings',
  AudioRoomsEarnings = "audioRoomsEarnings",
  VideoRoomsEarnings = "videoRoomsEarnings",
  ShopSalesEarnings = "shopSalesEarnings",
  RaffleDrawsEarnings = "raffleDrawsEarnings",
  PromoCodesEarnings = "promoCodesEarnings",
  VuzPayEarnings = "vuzPayEarnings"
} 