const Helpers = {
  getAxiosHeadersConfig: () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    };

    return config;
  },
  getUrlParameter: (name) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${  name  }=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  isEmail(text) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase());
  },
  imageSize(url) {
    const img = document.createElement('img');

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  },
  formatBytesSize(bytes) {
    const megabytes = bytes / (1024 * 1024);
    const gigabytes = bytes / (1024 * 1024 * 1024);

    if (gigabytes >= 1) {
      return `${gigabytes.toFixed(1)} GB`;
    }
 
      return `${megabytes.toFixed(1)} MB`;
    
  },
  formatBytesUploadSpeed(bytes) {
    const uploadSpeedMBPerSec = bytes / (1024 * 1024);

    return `${uploadSpeedMBPerSec.toFixed(2)} MB/s`;
  },
  formatSecondsTime(seconds) {
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${remainingMinutes} min, ${remainingSeconds} sec`;
  },
  formatUploadInfo(fileSize, uploadInfo) {
    if (!uploadInfo) {return '';}

    const formattedUploadSize = Helpers.formatBytesSize(uploadInfo.uploadedSize);
    const formattedFileSize = Helpers.formatBytesSize(fileSize);
    const formattedUploadSpeed = Helpers.formatBytesUploadSpeed(uploadInfo.uploadSpeed);
    const formattedRemainingTime = Helpers.formatSecondsTime(uploadInfo.remainingTime);
    return `${formattedUploadSize} of ${formattedFileSize} (${formattedUploadSpeed}) | ${formattedRemainingTime} remaining`;
  },
};

export default Helpers;
