import React, { useMemo } from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/icons/CheckIcon.svg';
import CustomTooltip from '../../customTooltip/CustomTooltip';
import WidgetTitle from '../widgetTitle/WidgetTitle';
import styles from './MilestonesWidget.module.scss';

const MilestonesWidget = ({ data }) => {
  const milestones = [
    {
      milestone: 1,
      needForReach: data.milestone1UsersMin,
      bonus: data.milestone1Bonus,
      text: 'one',
    },
    {
      milestone: 2,
      needForReach: data.milestone2UsersMin,
      bonus: data.milestone2Bonus,
      text: 'two',
    },
    {
      milestone: 3,
      needForReach: data.milestone3UsersMin,
      bonus: data.milestone3Bonus,
      text: 'three',
    },
  ];

  const reachedMilestone = useMemo(() => {
    const milestone = calculateReachedMilestone(data);

    if (!milestone) {return null;}

    return milestones.find((storedMilestone) => storedMilestone.milestone === parseInt(milestone));
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.header}>
          <WidgetTitle
            description="Data will be updated in the beginning of each month"
            title="Milestones"
          />
          <div className={styles.bonus}>
            <span className={styles.bonusAmount}>{reachedMilestone?.bonus || 0}$</span>
            <div className={styles.onTrack}>
              <CheckIcon />
              <span>On track</span>
            </div>
          </div>
        </div>
        <div className={styles.milestoneInfo}>
          <h1>{data.actualInstalls} Users</h1>
          <h1>This month</h1>
          <p>
            {reachedMilestone
              ? `You reached Milestone ${reachedMilestone.text}`
              : `You haven't reached Milestone`}
          </p>
        </div>
      </div>
      <div className={styles.milestoneContainer}>
        <section
          className={styles.progress}
          style={{ width: `calc(100% / 3 * ${reachedMilestone?.milestone})` }}
         />
        {milestones.map((milestone) => (
          <div key={milestone.milestone}>
            <span>{milestone.milestone}</span>
            <CustomTooltip value={milestone.bonus} />
            <p>{milestone.needForReach}</p>
          </div>
        ))}
      </div>
      <p>
        Users <span>0</span>
      </p>
    </div>
  );
};

const calculateReachedMilestone = (data) => {
  const milestoneMap = {
    1: [data.milestone1UsersMin, data.milestone1UsersMax],
    2: [data.milestone2UsersMin, data.milestone2UsersMax],
    3: [data.milestone3UsersMin],
  };

  let reachedMilestone = null;

  for (const milestone in milestoneMap) {
    const [min, max] = milestoneMap[milestone];

    if (data.actualInstalls >= min && (data.actualInstalls <= max || !max)) {
      reachedMilestone = milestone;
      break;
    }
  }

  return reachedMilestone;
};

export default MilestonesWidget;
