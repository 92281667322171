import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../../layout/PrivateRoute';
import EnterPin from '../views/Auth/EnterPin/EnterPin';
import ForgotPassword from '../views/Auth/ForgotPassword';
import Login from '../views/Auth/Login';
import Register from '../views/Auth/Register';
import ResetPassword from '../views/Auth/ResetPassword';
import NotFound from '../views/NotFound';
import Upload from '../views/Upload/Upload';
import Uploads from '../views/Uploads/Uploads';

export const Routes = () => (
  <Switch>
    <Route component={Register} exact path="/register" />
    <Route component={ForgotPassword} exact path="/forgot-password" />
    <Route component={EnterPin} exact path="/enter-pin" />
    <Route component={ResetPassword} exact path="/reset-password" />
    <Route component={Login} exact path="/login" />
    <PrivateRoute component={Upload} exact path="/upload" />
    <PrivateRoute component={Uploads} exact path="/uploads" />
    <Route component={NotFound} />
  </Switch>
);
