import { useMemo } from 'react';
import Big from 'big.js';
import {
  IDashboardInitialEarnings,
  IEarningChart,
  IInfluencerInfo,
  ILatestVideo,
  ITopVideo,
  ITotalEarnings,
  IVuzPayConversion,
} from '../../../common/types';
import { useFetch } from '../../../hooks';
import Helpers from '../../utils/Helpers';
import { getDashboardInitialEarningsData } from './DashboardInitialEarningsData';

type EarningsMapItem = {
  key: keyof IDashboardInitialEarnings['extended'];
  data?: IEarningChart[] | null;
};

const transformEarningsData = (data: ITotalEarnings | null) => {
  if (!data) {
    return {
      totalEarnings: 0,
      earnings: [],
      configuration: {},
    };
  }

  const earnings = getDashboardInitialEarningsData(data.configuration);

  const earningsDataMap = [
    data.configuration?.videoViewsEarningEnabled
      ? {
          key: 'videoEarnings',
          data: data.videosEarnings?.chart,
        }
      : null,
    data.configuration?.audioRoomsEarningEnabled
      ? {
          key: 'voiceRoomsEarnings',
          data: data.audioRoomsEarnings?.chart,
        }
      : null,
    data.configuration?.videoRoomsEarningEnabled
      ? {
          key: 'videoRoomsEarnings',
          data: data.videoRoomsEarnings?.chart,
        }
      : null,
    data.configuration?.shopRevenueEnabled
      ? {
          key: 'shopSalesEarnings',
          data: data.shopSalesEarnings?.chart,
        }
      : null,
    data.configuration?.raffleDrawEarningEnabled
      ? {
          key: 'raffleDrawEarnings',
          data: data.raffleDrawsEarnings?.chart,
        }
      : null,
    data.configuration?.promoCodeEarningEnabled
      ? {
          key: 'promoCodesEarnings',
          data: data.promoCodesEarnings?.chart,
        }
      : null,
  ].filter(Boolean) as EarningsMapItem[];

  let totalEarnings = Big(0);

  earningsDataMap.forEach((earningData) => {
    if (!earningData?.data) {
      return;
    }

    const groupedEarningsDataByMonth = Helpers.groupByDate({
      data: earningData.data,
      groupBy: 'month',
    });

    // Map ISO month to read formatted one
    const formattedGroupedEarningsDataByMonth: Record<string, IEarningChart[]> = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in groupedEarningsDataByMonth) {
      if (groupedEarningsDataByMonth[key]) {
        const monthName = Helpers.isoMonthNumberToShortString(key);

        if (monthName) {
          formattedGroupedEarningsDataByMonth[monthName] = groupedEarningsDataByMonth[key];
        }
      }
    }

    earnings.forEach((data) => {
      if (data.extended[earningData.key]) {
        const earningsByMonth = formattedGroupedEarningsDataByMonth[data.name] || [];

        const earning = +Helpers.calculateSum(earningsByMonth, 'earning').toFixed(3);

        let value = Big(data.value);
        value = value.plus(earning);
        // eslint-disable-next-line no-param-reassign
        data.value = value.toNumber();

        totalEarnings = totalEarnings.plus(earning);

        // eslint-disable-next-line no-param-reassign
        data.extended[earningData.key]!.count = earningsByMonth.length || 0;
        // eslint-disable-next-line no-param-reassign
        data.extended[earningData.key]!.earning = earning;
      }
    });
  });

  return {
    totalEarnings: Helpers.formatNumber(totalEarnings.toNumber()),
    earnings,
    configuration: data.configuration,
  };
};

const transformTopWatchedVideosData = (data: ITopVideo[] | null) =>
  data?.map((video) => ({
    img: { field: 'Thumbnail', value: <img alt={video.title} src={video.thumbnail ?? ''} /> },
    title: { field: 'Video Title', value: video.title },
    views: { field: 'Views', value: Helpers.formatNumber(video.views) },
    likes: { field: 'Likes', value: Helpers.formatNumber(video.likes) },
    shares: { field: 'Shares', value: Helpers.formatNumber(video.shares) },
    earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(video.earning)}` },
  })) || [];

export const useDashboardData = () => {
  const totalFollowers = useFetch<number>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/followers`
  );
  const comments = useFetch<number>(`${process.env.REACT_APP_CREATOR_URL}/dashboard/comments`);
  const receivedGifts = useFetch<number>(`${process.env.REACT_APP_CREATOR_URL}/dashboard/gifts`);
  const uniqueViews = useFetch<number>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/unique-views`
  );
  const influencerInfo = useFetch<IInfluencerInfo>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/influencer`
  );
  const totalEarnings = useFetch<ITotalEarnings>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/earnings`
  );
  const topWatchedVideos = useFetch<ITopVideo[]>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/top-videos`
  );
  const latestVideos = useFetch<ILatestVideo>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/latest-video`
  );
  const vuzPayConversion = useFetch<IVuzPayConversion>(
    `${process.env.REACT_APP_CREATOR_URL}/dashboard/vuz-pay-conversion`
  );

  const transformedEarningsData = useMemo(
    () => transformEarningsData(totalEarnings.data),
    [totalEarnings.data]
  );

  const transformedTopWatchedVideosData = useMemo(
    () => transformTopWatchedVideosData(topWatchedVideos.data),
    [topWatchedVideos.data]
  );

  return {
    isTotalFollowersLoading: totalFollowers.isLoading,
    isCommentsLoading: comments.isLoading,
    isReceivedGiftsLoading: receivedGifts.isLoading,
    isUniqueViewsLoading: uniqueViews.isLoading,
    isTotalEarningsLoading: totalEarnings.isLoading,
    isInfluencerInfoLoading: influencerInfo.isLoading,
    isTopWatchedVideosLoading: topWatchedVideos.isLoading,
    isLatestVideosLoading: latestVideos.isLoading,
    isVuzPayAnalyticsLoading: vuzPayConversion.isLoading,
    totalFollowers: totalFollowers.data,
    comments: comments.data,
    receivedGifts: receivedGifts.data,
    uniqueViews: uniqueViews.data,
    influencerInfo: influencerInfo.data,
    totalEarnings: transformedEarningsData,
    topWatchedVideos: transformedTopWatchedVideosData,
    latestVideos: latestVideos.data,
    vuzPayConversion: vuzPayConversion.data,
  };
};
