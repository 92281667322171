import React from 'react';
import { ReactComponent as LoaderIcon } from '../../assets/icons/LoaderIcon.svg';
import styles from './Loader.module.scss';

const Loader = ({ width = 50, height = 50 }) => (
    <div>
      <LoaderIcon className={styles.loader} height={height} width={width} />
    </div>
  );

export default Loader;
