import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import Helpers from '../../../utils/Helpers';
import styles from './VideoStatsWidget.module.scss';

const VideoStatsWidget = ({ data, loading = false }) => {
  const widgetData = [
    { name: 'Total Views', value: Helpers.formatNumber(data?.views) },
    { name: 'Likes', value: Helpers.formatNumber(data?.likes) },
    { name: 'Comments', value: Helpers.formatNumber(data?.comments) },
  ];

  return (
    <div className={classNames(styles.videoStatsContainer, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <h1>Latest video performance</h1>
          {data && (
            <>
              <div className={styles.videoContainer}>
                {data?.thumbnail && <img src={data.thumbnail} />}
              </div>
              <div className={styles.videoInfo}>
                <h1>{data?.title}</h1>
                <p>{Helpers.formatDate(data?.createDatetime)}</p>
              </div>
              <div className={styles.videoDescription}>
                {widgetData.map((data, index) => (
                    <div key={index}>
                      <p>{data.name}</p>
                      <h1>{data.value || 0}</h1>
                    </div>
                  ))}
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default VideoStatsWidget;
