import { useMemo, useState } from 'react';
import { useFetch } from '../../../hooks';
import { applyQueryParameters } from '../../../utils';
import PeriodSelectUtils, { IDatePeriod } from '../../components/periodSelect/PeriodSelectUtils';
import Helpers from '../../utils/Helpers';

const REQUEST_CACHE_TTL_IN_SECONDS = 30;
const CLICKS_AND_USERS_REQUEST_CACHE_TTL_IN_SECONDS = 10 * 60;

const totalViewsUrl = `${process.env.REACT_APP_CREATOR_URL}/analytics/total-views`;
const usersInfoUrl = `${process.env.REACT_APP_CREATOR_URL}/analytics/users-info`;
const clicksAndUsersUrl = `${process.env.REACT_APP_CREATOR_URL}/analytics/clicks-and-users`;
const videosTypesUrl = `${process.env.REACT_APP_CREATOR_URL}/analytics/videos-types`;

export const geographyColors = ['#074DFF', '#6AD2FF', '#1B2559', '#C6C7F8'];
export const videosTypeColors = ['#E9EDF7', '#074DFF', '#6AD2FF'];

interface IChart {
  all: number;
  date: string;
  unique: number;
}

interface ITotalViews {
  chart: IChart[];
  totalViews: number;
}

interface IGeography {
  continent: string;
  country: string;
  usersNumber: number;
}

interface IUsersInfo {
  geography: IGeography[];
  usersTypes: { freeUsers: number; vipUsers: number; totalUsers: number };
}

interface IClick {
  month: string;
  value: number;
}

interface IUser {
  month: string;
  value: number;
}

interface IClicksAndUsers {
  clicks: IClick[];
  users: IUser[];
}

interface IVideoTypes {
  free: number;
  vip: number;
  vipWithPreview: number;
  totalVideos: number;
}

const createClicksAndUsersRequestUrl = (): string => {
  const { fromDate, toDate } = PeriodSelectUtils.last5Months();

  return (
    applyQueryParameters(clicksAndUsersUrl, {
      from: fromDate?.toISOString().split('T')[0],
      to: toDate?.toISOString().split('T')[0],
    }) || clicksAndUsersUrl
  );
};

const transformVideosTypesData = (data?: IVideoTypes | null) => {
  if (!data) {return [];}

  return [
    {
      name: 'Free',
      value: Helpers.calculatePercentage(data.free, data.totalVideos),
      color: videosTypeColors[0],
    },
    {
      name: 'Vip video',
      value: Helpers.calculatePercentage(data.vip, data.totalVideos),
      color: videosTypeColors[1],
    },
    {
      name: 'Vip with Free Preview',
      value: Helpers.calculatePercentage(data.vipWithPreview, data.totalVideos),
      color: videosTypeColors[2],
    },
  ];
};

const transformTotalViewsData = (data?: ITotalViews | null) => {
  if (!data?.chart) {
    return [];
  }

  const groupedDataByDate = data.chart.reduce(
    (acc: Record<string, { all: number; unique: number }>, value) => {
      const [year, month] = value.date.split('-');

      const date = `${year}-${month}-01`;

      if (acc[date]) {
        acc[date].all += value.all;
        acc[date].unique += value.unique;
      } else {
        acc[date] = {
          all: value.all,
          unique: value.unique,
        };
      }

      return acc;
    },
    {},
  );

  return Object.entries(groupedDataByDate).map(([key, value]) => ({
    date: Helpers.getMonthAndYearFromDate(key, true),
    all: value.all,
    unique: value.unique,
  }));

  // return data.chart.reduce((acc: IChart[], entry) => {
  //   // aggregate views under 1 month if there are duplicate months
  //   const existingEntryIndex = acc.findIndex((e) => e.month === entry.month);
  //   if (existingEntryIndex === -1) {
  //     acc.push(entry);
  //   } else {
  //     acc[existingEntryIndex].all += entry.all;
  //     acc[existingEntryIndex].unique += entry.unique;
  //   }

  //   return acc;
  // }, []);
};

const transformClicksAndUsersData = (data?: IClicksAndUsers | null) => {
  if (!data) {return [];}

  return data.clicks?.map((click) => {
    const user = data.users?.find((u) => u.month === click.month);

    return {
      month: Helpers.getMonthAndYearFromDate(click.month),
      clicks: click.value,
      users: user ? user.value : 0,
    };
  });
};

const transformGeographyData = (data?: IGeography[]) => {
  if (!data) {return [];}

  const total = data.reduce((sum, entry) => sum + entry.usersNumber, 0);
  const sortedData = data.sort((a, b) => b.usersNumber - a.usersNumber);

  const topCountries = sortedData.slice(0, 3).map((entry, index) => ({
    name: entry.country,
    value: Helpers.calculatePercentage(entry.usersNumber, total),
    color: geographyColors[index] || geographyColors[0],
  }));

  if (data.length > 3) {
    const otherCountries = 100 - topCountries.reduce((sum, entry) => sum + entry.value, 0);
    topCountries.push({
      name: 'Other',
      value: otherCountries,
      color: geographyColors[topCountries.length],
    });
  }

  return topCountries;
};

export const useAnalyticsData = () => {
  const [datePeriod, setDatePeriod] = useState<IDatePeriod>(PeriodSelectUtils.thisYear());

  const fromDate = datePeriod.fromDate?.toISOString().split('T')[0];
  const toDate = datePeriod.toDate?.toISOString().split('T')[0];
  const isDatePeriodSelected = fromDate && toDate;

  console.log(fromDate, toDate);

  const totalViews = useFetch<ITotalViews>(
    isDatePeriodSelected
      ? applyQueryParameters(totalViewsUrl, { from: fromDate, to: toDate })
      : totalViewsUrl,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  const usersInfo = useFetch<IUsersInfo>(
    isDatePeriodSelected
      ? applyQueryParameters(usersInfoUrl, { from: fromDate, to: toDate })
      : usersInfoUrl,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  const clicksAndUsers = useFetch<IClicksAndUsers>(createClicksAndUsersRequestUrl(), {
    cacheTtlInSeconds: CLICKS_AND_USERS_REQUEST_CACHE_TTL_IN_SECONDS,
  });

  const videosTypes = useFetch<IVideoTypes>(
    isDatePeriodSelected
      ? applyQueryParameters(videosTypesUrl, { from: fromDate, to: toDate })
      : videosTypesUrl,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  const usersTypes = usersInfo.data?.usersTypes;
  const geography = usersInfo.data?.geography;

  const transformedGeographyData = useMemo(() => transformGeographyData(geography), [geography]);

  const transformedVideosTypesData = useMemo(
    () => transformVideosTypesData(videosTypes.data),
    [videosTypes.data],
  );

  const transformedTotalViewsData = useMemo(
    () => transformTotalViewsData(totalViews.data),
    [totalViews.data],
  );

  console.log(transformedTotalViewsData);

  const transformedClicksAndUsersData = useMemo(
    () => transformClicksAndUsersData(clicksAndUsers.data),
    [clicksAndUsers.data],
  );

  return {
    setDatePeriod,
    isTotalViewsLoading: totalViews.isLoading,
    isUsersInfoLoading: usersInfo.isLoading,
    isClicksAndUsersLoading: clicksAndUsers.isLoading,
    isVideosTypesLoading: videosTypes.isLoading,
    usersTypes,
    geography: transformedGeographyData,
    videosTypes: transformedVideosTypesData,
    totalViews: transformedTotalViewsData,
    clicksAndUsers: transformedClicksAndUsersData,
    clicksAndUsersTrends: clicksAndUsers.data,
  };
};
