import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ReactComponent as InfoIcon } from '../../assets/icons/InfoIcon.svg';
import styles from './Tooltip.module.scss';

interface ITooltipProps {
  className?: string;
  width?: string | number;
  height?: string | number;
}

export const Tooltip: React.FC<PropsWithChildren<ITooltipProps>> = ({
  children,
  className,
  width = 16,
  height = 16,
}) => {
  const tooltipClassName = classNames(styles.tooltip, className);

  return (
    <div className={tooltipClassName}>
      <InfoIcon height={height} width={width} />
      <span>{children}</span>
    </div>
  );
};
