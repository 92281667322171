import { useEffect, useState } from 'react';
import axios, { isAxiosError } from 'axios';
import { cacheService } from '../utils/cache.service';

interface IUseFetchOptions {
  cacheTtlInSeconds?: number;
}

interface IUseFetch<T> {
  data: T | null;
  isLoading: boolean;
  error: string | Record<string, string | number | object> | null;
}

export function useFetch<T>(
  url?: string | null,
  { cacheTtlInSeconds }: IUseFetchOptions = { cacheTtlInSeconds: 0 },
): IUseFetch<T> {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | Record<string, string | number | object> | null>(
    null,
  );

  useEffect(() => {
    if (!url) {
      return;
    }

    const { token, cancel } = axios.CancelToken.source();

    if (cacheTtlInSeconds) {
      const cachedData = cacheService.get(`cached_request_${url}`);

      if (cachedData) {
        setData(cachedData);
        return;
      }
    }

    setIsLoading(true);

    axios
      .get(url, {
        cancelToken: token,
      })
      .then((response) => {
        if (cacheTtlInSeconds) {
          cacheService.set(`cached_request_${url}`, response.data, cacheTtlInSeconds);
        }

        setData(response.data);
      })
      .catch((error) =>
        setError(isAxiosError(error) ? error.response?.data : { message: error?.message }),
      )
      .finally(() => setIsLoading(false));

    return () => {
      cancel();
      setIsLoading(false);
    };
  }, [cacheTtlInSeconds, url]);

  return {
    data,
    isLoading,
    error,
  };
}
