import React from 'react';
import classNames from 'classnames';
import { ReactComponent as InfoIcon } from '../../../assets/icons/InfoIcon.svg';
import styles from './WidgetTitle.module.scss';

interface IWidgetTitleProps {
  className?: string;
  title: React.ReactNode;
  description: React.ReactNode;
}

const WidgetTitle: React.FC<IWidgetTitleProps> = ({ className, title, description }) => {
  const containerClassName = classNames(styles.container, className);

  return (
    <div className={containerClassName}>
      <span>{title}</span>
      <div className={styles.tooltip}>
        <InfoIcon />
        <p>{description}</p>
      </div>
    </div>
  );
};

export default WidgetTitle;
