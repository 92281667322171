import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Loader from '../../loader/Loader';
import styles from './TabsWidget.module.scss';

interface ITabWidgetData {
  label: string;
  key: string;
  value?: string | number;
  icon?: string;
  chart?: React.ReactNode;
  content?: React.ReactNode;
  table?: React.ReactNode;
}

interface ITabsWidgetProps {
  className?: string;
  data?: ITabWidgetData[];
  loading?: boolean | Record<string, boolean>;
}

const TabsWidget: React.FC<PropsWithChildren<ITabsWidgetProps>> = ({
  className,
  data,
  loading = false,
}) => {
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    if (!data) {
      return;
    }

    setActiveTab(data[0]?.label);
  }, [data]);

  const activeTabData = useMemo(
    () => data?.find((item) => item.label === activeTab),
    [activeTab, data]
  );

  if (!data) {
    return;
  }

  const tabsClassMap: Record<string | number, string> = {
    2: styles.reachTabs,
    5: styles.earningTabs,
    default: styles.tabs,
  };

  const tabsClassName = tabsClassMap[data?.length] || tabsClassMap.default;

  const isLoading = (key: string) => (typeof loading === 'boolean' ? loading : loading[key]);

  const tabsWidgetClassName = classNames(className, styles.container);

  return (
    <div className={tabsWidgetClassName}>
      <div className={tabsClassName}>
        {data.map((d) => {
          if (d.icon) {
            return (
              <div className={styles.tabWithoutChart} key={d.key}>
                <div className={styles.iconWrapper}>{d.icon}</div>
                <p>{d.label}</p>
                {!isLoading(d.key) ? <h1>{d.value}</h1> : <Loader />}
              </div>
            );
          }

          const handleClick = () => {
            setActiveTab(d.label);
          };

          const chartClassName = classNames(styles.tabWithChart, {
            [styles.activeTab]: activeTab === d.label,
          });

          return (
            <div className={chartClassName} key={d.key} onClick={handleClick}>
              <p>{d.label}</p>
              {!isLoading(d.key) ? <h1>{d.value}</h1> : <Loader />}
            </div>
          );
        })}
      </div>
      {activeTabData?.chart && !isLoading(activeTabData.key) && (
        <div
          className={classNames(
            styles.chartContainer,
            activeTabData?.table ? styles.earningsChart : ''
          )}
          key={activeTabData?.label}
        >
          {activeTabData?.chart}
        </div>
      )}
      {activeTabData?.content && <div className={styles.content}>{activeTabData.content}</div>}
      {activeTabData?.table && <div className={styles.table}>{activeTabData.table}</div>}
    </div>
  );
};

export default TabsWidget;
