import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import WidgetTitle from '../widgetTitle/WidgetTitle';
import styles from './InformationWidget.module.scss';

const InformationWidget = ({ name, description, data, chart, loading = false }) => (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <WidgetTitle description={description} title={name} />
          <div className={styles.info}>
            {data?.map((datum, i) => (
                <ul key={i}>
                  <li>
                    <div className={styles.dot} style={{ color: datum.color }} />
                    <span>{datum.name}</span>
                  </li>
                  <p>{datum.value}%</p>
                </ul>
              ))}
          </div>
          <div className={styles.chartWrapper}>{chart}</div>
        </>
      ) : null}
    </div>
  );

export default InformationWidget;
