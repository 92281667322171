import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import { customTickNumberFormatter } from '../../charts/AreaChart/numberFormatter';
import { CustomDot } from '../../charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../charts/LineChart/components/CustomLineChartNumberToolTip';
import CustomLineChart from '../../charts/LineChart/LineChart';
import WidgetTitle from '../widgetTitle/WidgetTitle';
import styles from './ClicksAndUsersChartWidget.module.scss';

const ClicksAndUsersChartWidget = ({ data, loading }) => {
  const containerClassName = classNames(styles.container, loading && commonStyles.loading);

  if (loading) {
    return <div className={containerClassName} />;
  }

  return (
    <div className={containerClassName}>
      <div className={styles.header}>
        <WidgetTitle
          description="Data is only displayed for the last 6 months"
          title="Clicks and Users"
        />
        <ul>
          <li>
            <div className={styles.dot} style={{ color: '#1B2559' }} />
            <span>Clicks</span>
          </li>
          <li>
            <div className={styles.dot} style={{ color: '#A3AED0' }} />
            <span>Users</span>
          </li>
        </ul>
      </div>
      <CustomLineChart
        CustomDot={CustomDot}
        CustomToolTip={CustomLineChartNumberToolTip}
        data={data}
        height={250}
        xCustomTickFormatter={(value) => value}
        xDataKey="month"
        yCustomTickFormatter={customTickNumberFormatter}
        yDataKey="clicks"
        yDataKey2="users"
      />
    </div>
  );
};

export default ClicksAndUsersChartWidget;
