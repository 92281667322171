import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { generateRandomColor } from './colorUtils';

const CustomPieChart = ({
  data,
  width,
  height,
  yDataKey = 'value',
  cx = '50%',
  cy = '50%',
  outerRadius = 80,
  stroke = 'none',
  colors = Array.from({ length: data.length }, () => generateRandomColor()),
}) => (
    <PieChart height={height} width={width}>
      <Pie cx={cx} cy={cy} data={data} dataKey={yDataKey} outerRadius={outerRadius}
stroke={stroke}>
        {data?.map((_, index) => (
          <Cell fill={colors[index % colors.length]} key={`cell-${index}`} />
        ))}
      </Pie>
    </PieChart>
  );

export default CustomPieChart;
