import React, { useState } from 'react';
import { ReactComponent as LinkIcon } from '../../assets/icons/LinkIcon.svg';
import { ReactComponent as TickIcon } from '../../assets/icons/TickIcon.svg';
import styles from './VuzPayLink.module.scss';

interface IVuzPayLinkProps {
  link: string;
}

export const VuzPayLink: React.FC<IVuzPayLinkProps> = ({ link }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleClick = async () => {
    await navigator.clipboard.writeText(link);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <span className={styles.container}>
      <span>{link}</span>
      <button onClick={handleClick}>
        {isCopied ? (
          <>
            <TickIcon fill="#074dff" height={24} width={24} />
            Copied
          </>
        ) : (
          <>
            <LinkIcon />
            Copy Link
          </>
        )}
      </button>
    </span>
  );
};
