import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { generateRandomColor } from '../PieChart/colorUtils';

const CustomDoughnutChart = ({
  data,
  width,
  height = 400,
  cx = '50%',
  cy = '50%',
  innerRadius = 60,
  outerRadius = 90,
  yDataKey = 'value',
  paddingAngle = 5,
  colors = Array.from({ length: data.length }, () => generateRandomColor()),
}) => (
    <PieChart height={height} width={width}>
      <Pie
        cx={cx}
        cy={cy}
        data={data}
        dataKey={yDataKey}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        paddingAngle={paddingAngle}
      >
        {data?.map((_, index) => (
          <Cell fill={colors[index % colors.length]} key={`cell-${index}`} />
        ))}
      </Pie>
    </PieChart>
  );

export default CustomDoughnutChart;
