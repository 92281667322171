import React, { useMemo } from 'react';
import {
  EarningsTabs,
  IAudioRoomsEarnings,
  IInfluencerEarningsConfiguration,
  IPromoCodeEarnings,
  IRaffleDrawEarnings,
  IShopSalesEarnings,
  IVideoEarnings,
  IVideoRoomsEarnings,
  IVuzPayEarnings,
} from '../../../common/types';
import { customTickNumberFormatter } from '../../components/charts/AreaChart/numberFormatter';
import { CustomDot } from '../../components/charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../components/charts/LineChart/components/CustomLineChartNumberToolTip';
import CustomLineChart from '../../components/charts/LineChart/LineChart';
import PeriodSelect from '../../components/periodSelect/PeriodSelect';
import { Tooltip } from '../../components/Tooltip';
import { VuzPayLink } from '../../components/VuzPayLink';
import EarningsWidget from '../../components/widgets/earningsWidget/EarningsWidget';
import MilestonesWidget from '../../components/widgets/milestonesWidget/MilestonesWidget';
import TableWidget from '../../components/widgets/tableWidget/TableWidget';
import TabsWidget from '../../components/widgets/tabsWidget/TabsWidget';
import Helpers from '../../utils/Helpers';
import styles from './Earnings.module.scss';
import { getEarningsDataMap, useEarningsData } from './use-earnings-data';

const formatXAxis = (tickItem: string | Date) => {
  const date = new Date(tickItem);
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

const transformEarningsData = (
  configuration: IInfluencerEarningsConfiguration | null,
  data: ITransformEarningsData
) => {
  if (!configuration) {
    return {
      totalEarnings: [],
      tabs: [],
    };
  }

  const initialEarningsDataMap = getEarningsDataMap(configuration, data);

  const earningsDataMap = initialEarningsDataMap?.map((earning) =>
    earning?.data ? earning : { key: earning.key, label: earning.label, value: 0, data: null }
  );

  const calculatedTabs: {
    key: string;
    label: string;
    value?: string;
    chart?: React.ReactNode;
    content?: React.ReactNode;
    table?: React.ReactNode;
  }[] = [];

  const totalEarnings: { name: string; value: string | number }[] = [];

  earningsDataMap?.forEach((tabData) => {
    if (!tabData.data) {
      calculatedTabs.push({
        key: tabData.key,
        label: tabData.label,
      });
      return;
    }

    const totalTabEarnings = Helpers.calculateSum(tabData.data);

    const groupedTotalTabEarningsByDate = Helpers.groupByDate({
      data: tabData.data,
    });

    const mappedGroupedTotalTabEarningsByDate = groupedTotalTabEarningsByDate
      ? Object.entries(groupedTotalTabEarningsByDate)
          .map(([key, array]) => ({ name: key, value: Helpers.calculateSum(array) }))
          .sort((a, b) => Date.parse(a.name) - Date.parse(b.name))
      : [];

    totalEarnings.push(...mappedGroupedTotalTabEarningsByDate);

    calculatedTabs.push({
      key: tabData.key,
      label: tabData.label,
      value: `$${Helpers.formatNumber(totalTabEarnings)}`,
      chart: mappedGroupedTotalTabEarningsByDate.length ? (
        <CustomLineChart
          CustomDot={CustomDot}
          CustomToolTip={CustomLineChartNumberToolTip}
          data={mappedGroupedTotalTabEarningsByDate}
          height={280}
          toolTipEndCharacter="$"
          xCustomTickFormatter={formatXAxis}
          yCustomTickFormatter={customTickNumberFormatter}
        />
      ) : null,
      content:
        tabData.key === EarningsTabs.VuzPayEarnings && configuration.vuzPayDeepLink ? (
          <div className={styles['vuz-pay-link-container']}>
            <div className={styles['vuz-pay-link-header']}>
              <h1>VUZ Pay Campaign</h1>
              <Tooltip>Data</Tooltip>
            </div>
            <p className={styles['vuz-pay-link-description']}>
              Share this link for your VUZ Pay Campaign
            </p>
            <VuzPayLink link={configuration.vuzPayDeepLink} />
          </div>
        ) : null,
      table: (
        <TableWidget
          className={tabData.key === EarningsTabs.VideoEarnings ? styles.videoEarningsTable : null}
          data={tabData.tableData}
          title={tabData.tableTitle}
        />
      ),
    });
  });

  return {
    totalEarnings,
    tabs: calculatedTabs,
  };
};

const Earnings: React.FC = () => {
  const {
    setDatePeriod,
    isTotalDataLoading,
    milestonesEarnings,
    configuration,
    videosEarnings,
    audioRoomsEarnings,
    videoRoomsEarnings,
    shopSalesEarnings,
    raffleDrawsEarnings,
    promoCodesEarnings,
    vuzPayEarnings,
    isVideoEarningsLoading,
    isAudioRoomsEarningsLoading,
    isVideoRoomsEarningsLoading,
    isShopSalesEarningsLoading,
    isRaffleDrawsEarningsLoading,
    isPromoCodesEarningsLoading,
    isVuzPayEarningsLoading,
  } = useEarningsData();

  const transformedEarningsData = useMemo(
    () =>
      transformEarningsData(configuration, {
        videosEarnings,
        audioRoomsEarnings,
        videoRoomsEarnings,
        shopSalesEarnings,
        raffleDrawsEarnings,
        promoCodesEarnings,
        vuzPayEarnings,
      }),
    [
      configuration,
      videosEarnings,
      audioRoomsEarnings,
      videoRoomsEarnings,
      shopSalesEarnings,
      raffleDrawsEarnings,
      promoCodesEarnings,
      vuzPayEarnings,
    ]
  );

  const dataLoading: Record<string, boolean> = {
    videoEarnings: isVideoEarningsLoading,
    audioRoomsEarnings: isAudioRoomsEarningsLoading,
    videoRoomsEarnings: isVideoRoomsEarningsLoading,
    shopSalesEarnings: isShopSalesEarningsLoading,
    raffleDrawsEarnings: isRaffleDrawsEarningsLoading,
    promoCodesEarnings: isPromoCodesEarningsLoading,
    vuzPayEarnings: isVuzPayEarningsLoading,
  };

  return (
    <div className={styles.container}>
      <div>
        <p>Earnings details</p>
        <PeriodSelect onValueChange={setDatePeriod} value={3} />
      </div>
      <div className={styles.topWidgetsContainer}>
        {milestonesEarnings && (
          <div>
            <MilestonesWidget data={milestonesEarnings} />
          </div>
        )}
        <div>
          <EarningsWidget
            data={transformedEarningsData.totalEarnings}
            loading={isTotalDataLoading}
          />
        </div>
      </div>
      <TabsWidget
        className={styles.tabsWidgetContainer}
        data={transformedEarningsData.tabs}
        loading={dataLoading}
      />
    </div>
  );
};

interface ITransformEarningsData {
  videosEarnings: { data: IVideoEarnings | null };
  audioRoomsEarnings: { data: IAudioRoomsEarnings | null };
  videoRoomsEarnings: { data: IVideoRoomsEarnings | null };
  shopSalesEarnings: { data: IShopSalesEarnings | null };
  raffleDrawsEarnings: { data: IRaffleDrawEarnings | null };
  promoCodesEarnings: { data: IPromoCodeEarnings | null };
  vuzPayEarnings: { data: IVuzPayEarnings | null };
}

export default Earnings;
