import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as AnalyticsIcon } from '../../dashboard/assets/icons/AnalyticsIcon.svg';
import { ReactComponent as DashboardIcon } from '../../dashboard/assets/icons/DashboardIcon.svg';
import { ReactComponent as EarningsIcon } from '../../dashboard/assets/icons/EarningsIcon.svg';
import { ReactComponent as LogOutIcon } from '../../dashboard/assets/icons/LogOutIcon.svg';
import { ReactComponent as VideoIcon } from '../../dashboard/assets/icons/VideoIcon.svg';
import { logout } from '../../store/auth';
import { ReactComponent as UploadIcon } from '../../upload/assets/icons/upload.svg';
import { ReactComponent as UploadsIcon } from '../../upload/assets/icons/uploads.svg';
import styles from './PrivateLayout.module.scss';

const sideMenu = [
  {
    link: '/dashboard',
    iconComponent: <DashboardIcon />,
    title: 'Dashboard',
    showMobile: false,
  },
  {
    link: '/dashboard/myVideos',
    iconComponent: <VideoIcon />,
    title: 'My Videos',
    showMobile: false,
  },
  {
    link: '/dashboard/analytics',
    iconComponent: <AnalyticsIcon />,
    title: 'Channel Analytics',
    showMobile: false,
  },
  {
    link: '/dashboard/earnings',
    iconComponent: <EarningsIcon />,
    title: 'Earnings',
    showMobile: false,
  },
  {
    link: '/upload',
    iconComponent: <UploadIcon />,
    title: 'Upload Content',
    showMobile: false,
  },
  {
    link: '/uploads',
    iconComponent: <UploadsIcon />,
    title: 'Your Uploads',
    showMobile: true,
  },
];

const PrivateLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();

  const { pathname } = history.location;

  const [selectedItem, setSelectedItem] = useState(pathname);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedItem(pathname.startsWith('/dashboard/myVideos') ? '/dashboard/myVideos' : pathname);
  }, [pathname]);

  const handleLogout = () => {
    dispatch(logout(history) as any);
  };

  return (
    <main className={styles.layout}>
      <aside className={styles.sideBarContainer}>
        <div>
          <Link className={styles.logo} to="/">
            <img alt="logo" src="/assets/images/vuz_creators_logo_vertical.png" />
          </Link>
          {sideMenu.map((sideMenuElement) => {
            const menuLinkClassName = classNames(styles.menuItem, {
              [styles.selectedMenuItem]: selectedItem === sideMenuElement.link,
            });

            return (
              <NavLink key={sideMenuElement.link} to={sideMenuElement.link}>
                <div className={menuLinkClassName}>
                  <div className={styles.menuItemIconContainer}>
                    {sideMenuElement.iconComponent}
                  </div>
                  <p>{sideMenuElement.title}</p>
                </div>
              </NavLink>
            );
          })}
        </div>
        <div className={styles.logOutContainer} onClick={handleLogout}>
          <LogOutIcon />
          <p>Log Out</p>
        </div>
      </aside>
      <div className={styles.mainSection}>{children}</div>
    </main>
  );
};

export default PrivateLayout;
