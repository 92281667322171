import React from 'react';
import classNames from 'classnames';
import commonStyles from '../../../assets/styles/Common.module.scss';
import Helpers from '../../../utils/Helpers';
import WidgetTitle from '../widgetTitle/WidgetTitle';
import styles from './DataTrendsWidget.module.scss';

const DataTrendsWidget = ({ data, loading = false }) => (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <WidgetTitle
            description="Number of times users clicked on the deep link and users who installed the VUZ App"
            title="Clicks and Users"
          />
          <table className={styles.tableWrapper}>
            <thead>
              <tr>
                <th />
                <th>Clicks</th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>
              {data?.clicks?.map((click, index) => (
                <tr key={index}>
                  <td>{Helpers.formatShortDate(click.month)}</td>
                  <td>{click.value || 0}</td>
                  <td>{data.users[index].value || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  );

export default DataTrendsWidget;
